import React, { useRef, useState } from 'react';
import Canvas, { CanvasRef } from './Canvas';
import { evaluateImage } from '../../api/imageEvaluation';

interface GuidanceMarker {
  id: string;
  x: number;
  y: number;
  text: string;
}

interface TextInput {
  text: string;
  x: string;
  y: string;
  size: string;
}

const CanvasWrapper: React.FC = () => {
  const canvasRef = useRef<CanvasRef>(null);
  const [isPanelVisible, setIsPanelVisible] = useState(false);
  const [currentTool, setCurrentTool] = useState<'pen' | 'eraser'>('pen');
  const [isLoading, setIsLoading] = useState(false);
  const [guidanceMarkers, setGuidanceMarkers] = useState<GuidanceMarker[]>([]);
  const [selectedMarker, setSelectedMarker] = useState<string | null>(null);
  const [textInput, setTextInput] = useState<TextInput>({
    text: '',
    x: '0',
    y: '0',
    size: '16',
  });

  const handleAddText = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Form submitted');
    console.log('canvasRef.current exists:', !!canvasRef.current);
    console.log('textInput:', textInput);

    const x = parseInt(textInput.x, 10) * 32;
    const y = parseInt(textInput.y, 10) * 32;

    console.log('Calculated position:', { x, y });

    canvasRef.current?.renderText({
      text: textInput.text,
      x,
      y,
      size: parseInt(textInput.size, 10),
    });
  };

  const handleDownloadScreenshot = () => {
    const screenshot = canvasRef.current?.captureScreenshot();
    if (!screenshot) return;

    const link = document.createElement('a');
    link.download = `canvas-screenshot-${Date.now()}.png`;
    link.href = screenshot;
    link.click();
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const imageUrl = event.target?.result as string;
        canvasRef.current?.loadImage(imageUrl);
      };
      reader.readAsDataURL(file);
    }
  };

  const getCanvasBase64 = (): string | null => {
    const canvas = canvasRef.current?.getCanvas();
    return canvas?.toDataURL('image/png').split(',')[1] ?? null;
  };

  const handleAIAssistance = async (mode: 'guidance' | 'marking') => {
    try {
      setIsLoading(true);
      const base64Image = getCanvasBase64();

      if (!base64Image) {
        throw new Error('Failed to capture canvas image');
      }

      const textPositions = await evaluateImage(base64Image, mode);

      // Convert text positions to guidance markers
      const markers = textPositions.map((position, index) => ({
        id: `marker-${index}`,
        x: position.x * 32,
        y: position.y * 32,
        text: position.text,
      }));

      setGuidanceMarkers(markers);
    } catch (error) {
      console.error('AI assistance error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="relative z-10">
      <Canvas ref={canvasRef} gridSize={32} currentTool={currentTool} />

      {/* Guidance Markers with Dropdown */}
      {guidanceMarkers.map((marker) => (
        <div
          key={marker.id}
          className="absolute"
          style={{
            left: `${marker.x}px`,
            top: `${marker.y}px`,
            transform: 'translate(-50%, -50%)',
            zIndex: 20,
          }}
        >
          <div className="relative">
            <button
              type="button"
              className="w-6 h-6 bg-blue-500 z-10 rounded-full text-white text-xs flex items-center justify-center hover:bg-blue-600 transition-colors"
              onClick={() => setSelectedMarker(selectedMarker === marker.id ? null : marker.id)}
            >
              i
            </button>

            {selectedMarker === marker.id && (
              <div className="absolute z-50 top-full left-0 mt-2 w-48 bg-white rounded-lg shadow-lg p-3 text-sm">
                <p className="text-gray-700">{marker.text}</p>
              </div>
            )}
          </div>
        </div>
      ))}

      {/* Tool Buttons - Always visible */}
      <div className="fixed top-20 right-4 flex gap-2">
        <button
          onClick={() => setCurrentTool((prev) => (prev === 'pen' ? 'eraser' : 'pen'))}
          className="btn btn-circle btn-sm"
          title={currentTool === 'pen' ? 'Switch to Eraser' : 'Switch to Pen'}
          type="button"
        >
          {currentTool === 'pen' ? '✏️' : '🧹'}
        </button>

        <button
          onClick={() => setIsPanelVisible(!isPanelVisible)}
          className="btn btn-circle btn-sm"
          title={isPanelVisible ? 'Hide Controls' : 'Show Controls'}
          type="button"
        >
          {isPanelVisible ? '×' : '≡'}
        </button>
      </div>

      {/* Control Panel - Conditionally rendered */}
      {isPanelVisible && (
        <div className="fixed top-28 right-4 flex flex-col gap-2 bg-white p-4 rounded-lg shadow-lg min-w-[250px]">
          {/* Image Upload Collapse */}
          <div className="collapse bg-base-200">
            <input type="checkbox" />
            <div className="collapse-title text-sm font-medium">
              Upload Question Image
            </div>
            <div className="collapse-content">
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                className="border p-1 rounded w-full"
              />
            </div>
          </div>

          {/* Text Input Collapse */}
          <div className="collapse bg-base-200">
            <input type="checkbox" />
            <div className="collapse-title text-sm font-medium">
              Add Text to Canvas
            </div>
            <div className="collapse-content">
              <form onSubmit={handleAddText} className="flex flex-col gap-2">
                <div className="flex flex-col">
                  <label className="text-sm text-gray-600">Text:</label>
                  <input
                    type="text"
                    value={textInput.text}
                    onChange={(e) => setTextInput((prev) => ({ ...prev, text: e.target.value }))}
                    className="input input-bordered input-sm w-full"
                    placeholder="Enter text"
                  />
                </div>

                <div className="flex gap-2">
                  <div className="flex flex-col flex-1">
                    <label className="text-sm text-gray-600">Grid X:</label>
                    <input
                      type="number"
                      value={textInput.x}
                      onChange={(e) => setTextInput((prev) => ({ ...prev, x: e.target.value }))}
                      className="input input-bordered input-sm"
                      min="0"
                    />
                  </div>

                  <div className="flex flex-col flex-1">
                    <label className="text-sm text-gray-600">Grid Y:</label>
                    <input
                      type="number"
                      value={textInput.y}
                      onChange={(e) => setTextInput((prev) => ({ ...prev, y: e.target.value }))}
                      className="input input-bordered input-sm"
                      min="0"
                    />
                  </div>
                </div>

                <div className="flex flex-col">
                  <label className="text-sm text-gray-600">Font Size:</label>
                  <input
                    type="number"
                    value={textInput.size}
                    onChange={(e) => setTextInput((prev) => ({ ...prev, size: e.target.value }))}
                    className="input input-bordered input-sm"
                    min="8"
                  />
                </div>

                <button
                  type="submit"
                  className="btn btn-primary btn-sm"
                >
                  Add Text
                </button>
              </form>
            </div>
          </div>

          {/* AI Assistance Buttons */}
          <div className="flex flex-col gap-2 mt-2">
            <button
              type="button"
              onClick={() => handleAIAssistance('guidance')}
              className="btn btn-primary btn-sm"
              disabled={isLoading}
            >
              {isLoading ? 'Processing...' : 'Get AI Guidance'}
            </button>

            <button
              type="button"
              onClick={() => handleAIAssistance('marking')}
              className="btn btn-secondary btn-sm"
              disabled={isLoading}
            >
              {isLoading ? 'Processing...' : 'AI Mark Working'}
            </button>

            {/* Existing screenshot button */}
            <button
              type="button"
              onClick={handleDownloadScreenshot}
              className="btn btn-success btn-sm"
            >
              Download Screenshot
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CanvasWrapper;
