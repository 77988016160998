import React, { useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';

export interface AuthenticationLoginProps {
  switchMode: (mode: 'login' | 'signup' | 'reset') => void;
}

const AuthenticationLogin: React.FC<AuthenticationLoginProps> = ({ switchMode }) => {
  const [form, setForm] = useState(
    { email: '', password: '' },
  );
  const [error, setError] = useState('');
  const { login } = useAuth();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    login(
      form.email,
      form.password,
      () => {
        window.location.href = '/home';
      },
      (loginErr) => {
        if (loginErr) setError(loginErr);
      },
    );
  };

  return (
    <form onSubmit={handleSubmit} className="w-full">
      {
        error !== '' && (
        <div
          className="p-4 mb-4 text-sm text-red-800 border border-red-500 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
          role="alert"
        >
          <span className="font-medium">Authentication Failed! </span>
          {error}
        </div>
        )
      }

      <div className="mb-6">
        <label
          htmlFor="email"
          className="block mb-2 text-sm font-medium text-white"
        >
          Email address
          <input
            className="input input-bordered block w-full mt-1 text-base-content"
            type="email"
            id="email"
            name="email"
            required
            placeholder="mail@gmail.com"
            value={form.email}
            onChange={handleInputChange}
          />
        </label>
      </div>
      <div className="mb-6">
        <label htmlFor="password" className="block mb-2 text-sm font-medium text-white">
          Password
          <input
            className="input input-bordered block w-full mt-1 text-base-content"
            type="password"
            id="password"
            placeholder="•••••••••"
            name="password"
            value={form.password}
            onChange={handleInputChange}
            required
          />
        </label>
      </div>
      <div className="flex items-start mb-6">
        <label htmlFor="remember" className="ms-2 text-sm font-medium text-white">
          <div className="flex items-center h-5">
            <input id="remember" type="checkbox" value="" className="checkbox" required />
          </div>
          I agree with the
          <a href="/" className="text-blue-600 hover:underline dark:text-blue-500"> terms and conditions</a>
          .
        </label>
      </div>
      <div className="flex gap-2 ">
        <button type="submit" className="btn btn-primary">Submit</button>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => { switchMode('signup'); }}
        >
          Sign Up
        </button>
        <button
          type="button"
          className="text-gray-500 underline font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
          onClick={() => { switchMode('reset'); }}
        >
          Forgot Password?
        </button>
      </div>
    </form>
  );
};

export default AuthenticationLogin;
