import React, { useEffect, useState } from 'react';
import ScrollableContentLayout from '../layouts/ScrollableContentLayout';
import { SubjectSelection } from '../components/page-components/new-lesson/SubjectSelection';
import { TutorPreferences } from '../components/page-components/new-lesson/TutorPreferences';
import {
  emptyLessonProposal,
  LessonProposal,
} from '../types/collections/LessonProposal';
import { ReviewLesson } from '../components/page-components/new-lesson/ReviewLesson';
import { SelectTutor } from '../components/page-components/new-lesson/SelectTutor';
import { documentToUserDoc, UserDocument } from '../types/collections/User';
import FirebaseFirestoreService from '../services/database/strategies/FirebaseFirestoreService';
import { useAuth } from '../contexts/AuthContext';
import { TutorProfileType } from '../types/TutorProfile';
import { Chat } from '../types/collections/Chats';

export interface NewLessonProps {

}

type NewLessonSteps = 'Subject Selection' | 'Tutor Preferences' | 'Pick a Tutor' |'Review' ;
const LessonSteps: NewLessonSteps[] = ['Subject Selection', 'Tutor Preferences', 'Pick a Tutor', 'Review'];

export const NewLesson : React.FC<NewLessonProps> = () => {
  const [currentStep, setStep] = useState<NewLessonSteps>('Subject Selection');
  const [userDoc, setUserDoc] = useState<UserDocument | undefined>();
  const { authState } = useAuth();
  const [lessonProp, setLessonProp] = useState<LessonProposal>(emptyLessonProposal);
  const [selectedTutor, selectTutor] = useState<TutorProfileType | undefined>();
  const [selectedTutorUserDoc, setTutorUserDoc] = useState<UserDocument | undefined>();

  useEffect(() => {
    console.log(lessonProp);
  }, [lessonProp]);

  useEffect(() => {
    if (authState.isAuthenticated !== undefined && !authState.isAuthenticated) {
      window.location.href = '/authenticate';
    } else if (authState.user) {
      FirebaseFirestoreService.queryDocuments(
        'users',
        'uid',
        authState.user?.uid as string,
        'uid',
        1,
        undefined,
        (doc) => {
          if (doc.length > 0) {
            setUserDoc(documentToUserDoc(doc[0]));
          } else {
            window.location.href = '/authenticate';
          }
        },
        () => {
          window.location.href = '/authenticate';
        },
      );
    }
  }, [authState.user, authState.isAuthenticated]);

  const pickTutor = (tutorProfile: TutorProfileType, tutorUserDoc: UserDocument) => {
    selectTutor(tutorProfile);
    setTutorUserDoc(tutorUserDoc);
  };

  return (
    <ScrollableContentLayout>
      <div className="container mx-auto my-2 md:my-10  flex flex-wrap justify-center items-start gap-5 p-2">

        <ul className="steps md:steps-vertical steps-horizontal mx-10 max-w-3xl my-8 ">
          {LessonSteps.map((step, index) => {
            const isCompleted = index < LessonSteps.indexOf(currentStep);
            const isCurrent = step === currentStep;

            return (
              <li
                key={step}
                className={`step ${
                  index <= LessonSteps.indexOf(currentStep) ? 'step-primary' : ''
                }`}
                onClick={() => setStep(step)}
                aria-hidden="true"
              >
                <div className={`w-full  text-bold p-1 h-full md:h-auto md:p-4 rounded-lg transition-colors duration-200 flex items-center justify-between ${
                  isCompleted
                    ? 'text-green-700 border border-green-300 bg-green-50 dark:bg-gray-800 dark:border-green-800 dark:text-green-400'
                    : ''} 
                  ${isCurrent
                  ? 'text bg-primary border border-base-300 dark:bg-gray-800 dark:border-blue-800 dark:text-blue-400'
                  : 'text-gray-900 bg-gray-100 border border-gray-300 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400'
              }
                    `}
                >
                  <p className="font-bold text-xs md:text-base">{step}</p>
                </div>
              </li>
            );
          })}
        </ul>

        {/*  Main State */}
        {
          currentStep === 'Subject Selection'
          && (
            <SubjectSelection
              lessonPlan={lessonProp}
              setLessonPlan={setLessonProp}
              nextStep={() => { setStep('Tutor Preferences'); }}
            />
          )
        }

        {
          currentStep === 'Tutor Preferences'
          && (
          <TutorPreferences
            previousStep={() => { setStep('Subject Selection'); }}
            nextStep={() => { setStep('Pick a Tutor'); }}
            lessonPlan={lessonProp}
            setLessonPlan={setLessonProp}
          />
          )
        }

        {
          currentStep === 'Pick a Tutor' && userDoc
          && (
            <SelectTutor
              previousStep={() => { setStep('Tutor Preferences'); }}
              nextStep={() => { setStep('Review'); }}
              lessonPlan={lessonProp}
              selectTutor={pickTutor}
              selectedTutorUserDoc={selectedTutorUserDoc}
            />
          )
        }

        {
          currentStep === 'Review'
          && (
            <ReviewLesson
              previousStep={() => { setStep('Pick a Tutor'); }}
              nextStep={() => {
                if (selectedTutor && userDoc && selectedTutorUserDoc) {
                  // Fetch all chats for the selected tutor
                  FirebaseFirestoreService.queryDocuments<Chat>(
                    'chat',
                    'participant1Id',
                    selectedTutorUserDoc.uid,
                    'participant1Id',
                    100, // Increase limit to fetch more chats
                    undefined,
                    (docs1) => {
                      FirebaseFirestoreService.queryDocuments<Chat>(
                        'chat',
                        'participant2Id',
                        selectedTutorUserDoc.uid,
                        'participant2Id',
                        100, // Increase limit to fetch more chats
                        undefined,
                        (docs2) => {
                          // Combine all fetched chats
                          const allChats: Chat[] = [...docs1, ...docs2];

                          // Filter to find the correct chat
                          const existingChat = allChats.find((chat) => (
                            chat.participant1Id === selectedTutorUserDoc.uid
                              && chat.participant2Id === userDoc.uid
                          ) || (
                            chat.participant2Id === selectedTutorUserDoc.uid
                              && chat.participant1Id === userDoc.uid
                          ));

                          if (existingChat) {
                            // If chat exists, navigate to it
                            window.location.href = `/messages?chatId=${existingChat.id}`;
                          } else {
                            // If chat doesn't exist, create a new one
                            FirebaseFirestoreService.addDocument(
                              'chat',
                              {
                                participant1Id: userDoc.uid,
                                participant2Id: selectedTutorUserDoc.uid,
                                participant1Name: userDoc.fullName,
                                participant2Name: selectedTutorUserDoc.fullName,
                                lastMessage: 'New Lesson',
                                lastMessageTime: Date.now(),
                              },
                              (newChatId) => {
                                window.location.href = `/messages?chatId=${newChatId}`;
                              },
                              (error) => {
                                console.error('Error creating new chat:', error);
                              },
                            );
                          }
                        },
                        (error) => {
                          console.error('Error fetching chats for participant2Id:', error);
                        },
                      );
                    },
                    (error) => {
                      console.error('Error fetching chats for participant1Id:', error);
                    },
                  );
                }
              }}
              lessonPlan={lessonProp}
              selectedTutor={selectedTutor}
              selectedTutorUserDoc={selectedTutorUserDoc}
            />
          )
        }
      </div>
    </ScrollableContentLayout>
  );
};
