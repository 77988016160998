import React, { useEffect, useState } from 'react';
import { Check, Info, X } from 'lucide-react';
import {
  TutorProfileType, DefaultLevels, DefaultPricing,
} from '../../../../types/TutorProfile';
import { SubjectMapping, Subjects } from '../../../../types/Subjects';

interface TutorProfileFormProps {
  tutorProfileType: TutorProfileType;
  onSave: (data: TutorProfileType) => void;
}

const TutorProfileForm: React.FC<TutorProfileFormProps> = ({ tutorProfileType, onSave }) => {
  const [formData, setFormData] = useState<TutorProfileType>(tutorProfileType);
  const [showTooltip, setShowTooltip] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    setFormData(tutorProfileType);
  }, [tutorProfileType]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAvailabilityChange = (day: string, timeType: 'startTime' | 'endTime', value: string) => {
    setFormData((prevData) => ({
      ...prevData,
      availability: {
        ...prevData.availability,
        [day]: {
          ...prevData.availability[day],
          [timeType]: value,
        },
      },
    }));
  };

  const handlePricingChange = (subject: Subjects, level: string, price: number | '') => {
    const minPrice = DefaultPricing[level];
    const validPrice = price === '' ? '' : Math.max(Number(price), minPrice);

    setFormData((prevData) => {
      const newPricing = { ...prevData.pricing };
      if (!newPricing[subject]) {
        newPricing[subject] = {};
      }
      if (validPrice === '') {
        delete newPricing[subject][level];
        if (Object.keys(newPricing[subject]).length === 0) {
          delete newPricing[subject];
        }
      } else {
        newPricing[subject][level] = validPrice;
      }
      return { ...prevData, pricing: newPricing };
    });
  };

  const toggleTooltip = (subject: string, level: string) => {
    setShowTooltip((prev) => ({
      ...prev,
      [`${subject}-${level}`]: !prev[`${subject}-${level}`],
    }));
  };

  const toggleSubjectLevel = (subject: Subjects, level: string) => {
    setFormData((prevData) => {
      const newPricing = { ...prevData.pricing };
      if (!newPricing[subject]) {
        newPricing[subject] = { [level]: DefaultPricing[level] };
      } else {
        const updatedSubject = { ...newPricing[subject] };
        if (updatedSubject[level]) {
          delete updatedSubject[level];
        } else {
          updatedSubject[level] = DefaultPricing[level];
        }

        if (Object.keys(updatedSubject).length === 0) {
          delete newPricing[subject];
        } else {
          newPricing[subject] = updatedSubject;
        }
      }
      return { ...prevData, pricing: newPricing };
    });
  };

  return (
    <form onSubmit={(e) => { e.preventDefault(); onSave(formData); }} className="space-y-6">
      {/* Basic Information */}
      <div className="space-y-4">
        <div className="form-control">
          <label className="label" htmlFor="activeStudents">
            <span className="label-text">Active Students</span>
          </label>
          <input
            type="number"
            id="activeStudents"
            name="activeStudents"
            value={formData.activeStudents}
            onChange={handleChange}
            className="input input-bordered w-full"
            placeholder="Number of active students"
          />
        </div>
        <div className="form-control">
          <label className="label" htmlFor="experienceYears">
            <span className="label-text">Years of Experience</span>
          </label>
          <input
            type="number"
            id="experienceYears"
            name="experienceYears"
            value={formData.experienceYears}
            onChange={handleChange}
            className="input input-bordered w-full"
            placeholder="Years of teaching experience"
          />
        </div>
        <div className="form-control">
          <label className="label" htmlFor="description">
            <span className="label-text">Description</span>
          </label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            rows={4}
            className="textarea textarea-bordered w-full"
            placeholder="Write a brief description about yourself"
          />
        </div>
        <div className="form-control">
          <label className="label" htmlFor="biography">
            <span className="label-text">Biography (Markdown supported)</span>
          </label>
          <textarea
            id="biography"
            name="biography"
            value={formData.biography}
            onChange={handleChange}
            rows={6}
            className="textarea textarea-bordered w-full"
            placeholder="Write your detailed biography here (Markdown supported)"
          />
        </div>
        <div className="form-control">
          <label className="label" htmlFor="cvLink">
            <span className="label-text">CV Link</span>
          </label>
          <input
            type="text"
            id="cvLink"
            name="cvLink"
            value={formData.cvLink}
            onChange={handleChange}
            className="input input-bordered w-full"
            placeholder="Link to your CV"
          />
        </div>
      </div>

      {/* Availability */}
      <div className="space-y-4">
        <h3 className="text-lg font-semibold">Availability</h3>
        {Object.entries(formData.availability).map(([day, range]) => (
          <div key={day} className="flex items-center space-x-4">
            <span className="w-20 text-sm font-medium">{day}</span>
            <input
              type="time"
              value={range.startTime}
              onChange={(e) => handleAvailabilityChange(day, 'startTime', e.target.value)}
              className="input input-bordered w-full"
            />
            <input
              type="time"
              value={range.endTime}
              onChange={(e) => handleAvailabilityChange(day, 'endTime', e.target.value)}
              className="input input-bordered w-full"
            />
          </div>
        ))}
      </div>

      {/* Pricing */}
      <div className="space-y-4">
        <h3 className="text-lg font-semibold">Subjects & Pricing</h3>
        <p>
          For the subjects toggle the cross if you&apos;re planning on teaching that subject.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {SubjectMapping.map((subject) => (
            <div key={subject.id} className="card bg-base-200 shadow-xl">
              <div className="card-body">
                <h4
                  className="card-title"
                  style={{
                    background: `linear-gradient(to right, ${subject.colors.from}, ${subject.colors.to})`,
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  {subject.label}
                </h4>
                {DefaultLevels.map((level) => {
                  const price = formData.pricing[subject.label]?.[level];
                  const isTeaching = price !== undefined;
                  const tooltipKey = `${subject.label}-${level}`;
                  return (
                    <div key={level} className="flex items-center space-x-2 mb-2">
                      <button
                        type="button"
                        onClick={() => toggleSubjectLevel(subject.label, level)}
                        className="btn btn-circle btn-xs"
                      >
                        {isTeaching ? (
                          <Check className="w-4 h-4" />
                        ) : (
                          <X className="w-4 h-4" />
                        )}
                      </button>
                      <span className="flex-grow text-sm font-medium">{level}</span>
                      <div className="relative flex-shrink-0 w-24">
                        <input
                          type="number"
                          value={isTeaching ? price : ''}
                          onChange={(e) => handlePricingChange(subject.label, level, e.target.value ? Number(e.target.value) : '')}
                          className={`input input-bordered input-sm w-full ${
                            !isTeaching ? 'opacity-50 cursor-not-allowed' : ''
                          }`}
                          disabled={!isTeaching}
                          placeholder={isTeaching ? undefined : 'N/A'}
                          min={DefaultPricing[level]}
                        />
                        <div className="tooltip" data-tip={`Minimum price: £${DefaultPricing[level]}`}>
                          <button
                            type="button"
                            className="btn btn-circle btn-xs absolute right-1 top-1"
                            onClick={() => toggleTooltip(subject.label, level)}
                          >
                            <Info className="w-3 h-3" />
                          </button>
                        </div>
                        {
                          showTooltip[tooltipKey] && (
                            <div className="absolute z-10 right-0 top-0 p-2 bg-white border border-gray-200 rounded-lg shadow-lg dark:bg-gray-800 dark:border-gray-700">
                              <p className="text-sm text-gray-600 dark:text-gray-400">
                                Minimum price: £
                                {DefaultPricing[level]}
                              </p>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>

      <button
        type="button"
        onClick={() => { onSave(formData); }}
        className="btn btn-primary w-full"
      >
        Save Profile
      </button>
    </form>
  );
};

export default TutorProfileForm;
