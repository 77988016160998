import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FirebaseFirestoreService from '../services/database/strategies/FirebaseFirestoreService';

const PaymentCancelledHandler: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [messageId] = useState(searchParams.get('message_id'));
  const navigate = useNavigate();

  useEffect(() => {
    const updatePaymentStatus = async () => {
      console.log(messageId);
      if (messageId) {
        FirebaseFirestoreService.updateDocument(
          'messages',
          messageId,
          {
            status: 'cancelled',
          },
          () => {
            // Redirect to a different page, e.g., the home page or a cancellation confirmation page
            navigate('/messages');
          },
          (error) => {
            console.error('Failed to update payment status:', error);
          },
        );
      }
    };

    updatePaymentStatus();
  }, [messageId, navigate]);

  return (
    <div className="text-center bg-content flex items-center justify-center w-screen h-screen flex-col gap-2">
      <div className="flex justify-center items-center rounded-full border border-white">
        <div className="animate-spin rounded-full h-12 w-12 border-b-4 border-red-700" />
      </div>
      <h2 className="text-2xl text-gray-900 dark:text-white font-bold">Payment cancelled</h2>
      <p className="text-lg text-gray-800 dark:text-gray-200">Redirecting you back to the main page...</p>
    </div>
  );
};

export default PaymentCancelledHandler;
