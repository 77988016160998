import React from 'react';

export interface BreadcrumbStepperProps {
  stepCompletedIndex: number;
  stepNames: string[];
}

export const BreadcrumbStepper: React.FC<BreadcrumbStepperProps> = ({
  stepCompletedIndex,
  stepNames,
}) => (
  <ul className="steps steps-horizontal w-full">
    {stepNames.map((stepName, index) => (
      <li
        key={stepName}
        className={`step ${index <= stepCompletedIndex ? 'step-primary' : ''}`}
        data-content={index <= stepCompletedIndex ? '✓' : (index + 1).toString()}
      >
        {stepName}
      </li>
    ))}
  </ul>
);
