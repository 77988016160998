export interface ImageRequest {
    base64_image: string;
    media_type: string;
    prompt_type: 'guidance' | 'marking';
  }

export interface TextPosition {
    text: string;
    x: number;
    y: number;
    size: number;
  }

export const evaluateImage = async (
  imageBase64: string,
  mode: 'guidance' | 'marking',
): Promise<TextPosition[]> => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/evaluate-image/process-image`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        base64_image: imageBase64,
        media_type: 'image/png',
        prompt_type: mode,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    console.log('Response:', data);
    return data.content;
  } catch (error) {
    console.error('API call failed:', error);
    throw error;
  }
};
