import React from 'react';
import { Lesson } from '../../../../types/collections/Lesson';

interface LessonCardProps {
    lesson: Lesson;
  }

const LessonCard: React.FC<LessonCardProps> = ({ lesson }) => (
  <div className="card card-side w-full bg-base-100 shadow-xl">
    <figure className="w-1/3 h-full">
      <img
        src="https://placehold.co/400x400"
        alt="car!"
        className="w-full h-full object-cover"
      />
    </figure>
    <div className="card-body">
      <h3 className="card-title">{lesson.subject}</h3>
      <p>{lesson.level}</p>
      <p>
        Date:
        {lesson.startDate}
      </p>
      <p>
        Time:
        {lesson.time}
      </p>
      <p>
        Cost: $
        {lesson.cost}
      </p>
      {lesson.zoomLink && (
      <div className="card-actions justify-end">
        <a
          href={lesson.zoomLink}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-primary btn-sm"
        >
          Join Zoom Meeting
        </a>
      </div>
      )}
    </div>
  </div>
);

export default LessonCard;
