import React from 'react';
import { Linkedin, Mail, Twitter } from 'lucide-react';
import ScrollableContentLayout from '../layouts/ScrollableContentLayout';

export interface TeamPageProps {

}

const teamMembers = [
  {
    name: 'Ashman Misra', role: 'CEO & Founder', image: 'https://media.licdn.com/dms/image/v2/C4E03AQHoVaNHe1b2HQ/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1632689454937?e=1733356800&v=beta&t=7wj3hBjPap_M9B_guDKK15xH9hmjjOOB_XVH9vLCLK4', bio: 'Visionary leader with 15+ years in EdTech',
  },
  {
    name: 'Elijah Ahmad', role: 'CTO', image: 'https://media.licdn.com/dms/image/v2/D4E03AQFuOpD8ZmghxQ/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1683736650773?e=1733356800&v=beta&t=G2xtCi3SEAjPcioL9LMmcBDmn7gqDyS8OAjSFnaX4NY', bio: 'AI expert, former Palantir engineer',
  },
  {
    name: 'X', role: 'Lead Developer', image: '/api/placeholder/300/300', bio: 'Full-stack wizard, open-source contributor',
  },
  {
    name: 'X', role: 'UX Designer', image: '/api/placeholder/300/300', bio: 'Award-winning designer, user advocate',
  },
];

const TeamPage : React.FC<TeamPageProps> = () => (
  <ScrollableContentLayout>
    <div className="container mx-auto py-4">
      <div className="hero bg-base-200 min-h-[60vh]">
        <div className="hero-content text-center">
          <div className="max-w-md">
            <h1 className="text-5xl font-bold">The Team</h1>
            <p className="py-6">
              Get to know the talent behind Tütr.ai and see if you can fit into our
              current operations.
            </p>
            <button
              className="btn btn-primary"
              type="button"
            >
              Get Started
            </button>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {teamMembers.map((member, index) => (
          <div key={`${index.toString()}-value`} className="card bg-base-100 shadow-xl hover:shadow-2xl transition-shadow duration-300">
            <figure className="px-10 pt-10">
              <img src={member.image} alt={member.name} className="rounded-xl object-cover h-64 w-full" />
            </figure>
            <div className="card-body items-center text-center">
              <h2 className="card-title text-2xl font-bold">{member.name}</h2>
              <p className="text-primary font-semibold">{member.role}</p>
              <p className="mt-2">{member.bio}</p>
              <div className="card-actions justify-center mt-4">
                <button className="btn btn-circle btn-outline" type="button">
                  <Linkedin size={20} />
                </button>
                <button className="btn btn-circle btn-outline" type="button">
                  <Twitter size={20} />
                </button>
                <button className="btn btn-circle btn-outline" type="button">
                  <Mail size={20} />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

    </div>
  </ScrollableContentLayout>
);

export default TeamPage;
