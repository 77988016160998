import React, { useEffect, useState } from 'react';
import { LessonProposal } from '../../../types/collections/LessonProposal';
import { TutorProfileType } from '../../../types/TutorProfile';
import { UserDocument } from '../../../types/collections/User';
import FirebaseFirestoreService from '../../../services/database/strategies/FirebaseFirestoreService';
import { TutorProfileCard } from '../../tutor-profile-card/TutorProfileCard';

export interface SelectTutorProps {
  previousStep: () => void,
  nextStep: () => void,
  lessonPlan: LessonProposal,
  selectTutor: (tutor: TutorProfileType, tutorUserDoc: UserDocument) => void,
  selectedTutorUserDoc: UserDocument | undefined,
}

type TutorProfileAndUserDoc = {
  tutorProfile: TutorProfileType,
  userDoc: UserDocument
}

export const SelectTutor: React.FC<SelectTutorProps> = ({
  previousStep,
  nextStep,
  lessonPlan,
  selectTutor,
  selectedTutorUserDoc,
}) => {
  const [filteredTutors, setFilteredTutors] = useState<TutorProfileAndUserDoc[]>([]);

  useEffect(() => {
    FirebaseFirestoreService.getAllDocuments('tutor-profile', (docs) => {
      if (docs && docs.length > 0) {
        const tutorProfileDocs = docs.map((elem) => elem as TutorProfileType);
        const seenUserIds = new Set<string>();
        for (let index = 0; index < docs.length; index += 1) {
          if (docs[index] && docs[index].id) {
            if (docs[index].id && !seenUserIds.has(docs[index].id!)) {
              seenUserIds.add(docs[index].id!);
              FirebaseFirestoreService.queryDocuments(
                'users',
                'uid',
                docs[index].id!,
                'fullName',
                1,
                undefined,
                (userDocs) => {
                  if (userDocs.length > 0) {
                    const userDocument = userDocs[0] as UserDocument;
                    console.log('Entered here', {
                      tutorProfile: tutorProfileDocs[index],
                      userDoc: userDocument,
                    });
                    setFilteredTutors((prevState) => {
                      // Check if this tutor is already in the state
                      if (!prevState.some((tutor) => tutor.userDoc.uid === userDocument.uid)) {
                        return [...prevState, {
                          tutorProfile: tutorProfileDocs[index],
                          userDoc: userDocument,
                        }];
                      }
                      return prevState; // If the tutor is already in the state, don't add it again
                    });
                  }
                },
                (error) => {
                  console.log('Failed to get the documents...', docs[index].id, error);
                },
              );
            }
          }
        }
      }
    });
  }, [lessonPlan]);

  useEffect(() => {
    console.log('updated tutors', filteredTutors);
  }, [filteredTutors]);
  return (
    <div className="card flex-1 bg-base-100 shadow-xl">
      <div className="card-body">
        <h2 className="card-title text-3xl font-bold mb-4">Select a Tutor</h2>
        <p className="mb-6">
          Choose a tutor that best fits your needs and preferences.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
          {filteredTutors.map((elem) => (
            <TutorProfileCard
              key={elem.userDoc.uid}
              userDoc={elem.userDoc}
              tutorProfileType={elem.tutorProfile}
              pickTutor={() => {
                selectTutor(elem.tutorProfile, elem.userDoc);
              }}
              selected={selectedTutorUserDoc && elem.userDoc.uid === selectedTutorUserDoc.uid}
            />
          ))}
        </div>

        {filteredTutors.length === 0 && (
          <div className="alert alert-info">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
            <span>
              No tutors found matching your criteria.
              Please try adjusting your preferences.
            </span>
          </div>
        )}

        <div className="card-actions justify-between mt-6">
          <button
            type="button"
            onClick={previousStep}
            className="btn btn-outline"
          >
            Back
          </button>
          <button
            type="button"
            onClick={nextStep}
            className="btn btn-primary"
            disabled={!selectedTutorUserDoc}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};
