import React from 'react';
import ScrollableContentLayout from '../layouts/ScrollableContentLayout';
import CanvasWrapper from '../components/canvas/CanvasWrapper';

const Whiteboard: React.FC = () => (
  <ScrollableContentLayout>
    <CanvasWrapper />
  </ScrollableContentLayout>
);

export default Whiteboard;
