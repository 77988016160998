import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useDarkMode } from '../../contexts/DarkModeContext';

const Navbar: React.FC = () => {
  const [menuOpened, setMenuOpened] = useState(false);
  const { authState, logout } = useAuth();
  const { darkModeState, toggleDarkMode } = useDarkMode();
  const location = useLocation();
  const activeClass = 'bg-primary text-primary-content rounded-lg';

  return (
    <div className="navbar bg-neutral sticky top-0 z-50">
      <div className="navbar-start">
        <div className="dropdown">
          <button
            type="button"
            className="btn btn-ghost lg:hidden"
            onClick={() => setMenuOpened(!menuOpened)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
          </button>
          <ul className={`menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52 ${menuOpened ? '' : 'hidden'}`}>
            <li><Link to="/home" className={location.pathname === '/home' ? activeClass : ''}>Home</Link></li>
            {authState.user && <li><Link to="/messages" className={location.pathname === '/messages' ? activeClass : ''}>Messages</Link></li>}
            <li><Link to="/team" className={location.pathname === '/team' ? activeClass : ''}>Team</Link></li>
            <li><Link to="/contact" className={location.pathname === '/contact' ? activeClass : ''}>Contact</Link></li>
            <li><Link to="/content" className={location.pathname === '/contact' ? activeClass : ''}>Content</Link></li>
          </ul>
        </div>
        <Link to="/" className="btn btn-ghost normal-case text-xl">Tütr.ai</Link>
      </div>
      <div className="navbar-center hidden lg:flex">
        <ul className="menu menu-horizontal px-1">
          <li><Link to="/home" className={location.pathname === '/home' ? activeClass : ''}>Home</Link></li>
          {authState.user && <li><Link to="/messages" className={location.pathname === '/messages' ? activeClass : ''}>Messages</Link></li>}
          <li><Link to="/team" className={location.pathname === '/team' ? activeClass : ''}>Team</Link></li>
          <li><Link to="/contact" className={location.pathname === '/contact' ? activeClass : ''}>Contact</Link></li>
          <li><Link to="/content" className={location.pathname === '/content' ? activeClass : ''}>Content</Link></li>
        </ul>
      </div>
      <div className="navbar-end">
        {!authState.isAuthenticated && (
          <>
            <Link to="/authenticate" className="btn btn-ghost">Log in</Link>
            <Link to="/authenticate?mode=signup" className="btn btn-primary">Sign Up</Link>
          </>
        )}
        {authState.isAuthenticated && (
          <>
            <button
              className="btn btn-error"
              onClick={() => { logout(() => { window.location.href = '/'; }, () => { window.location.href = '/'; }); }}
              type="button"
            >
              Signout
            </button>
            <Link to="/settings" className="btn btn-ghost btn-circle">
              <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 12.25V1m0 11.25a2.25 2.25 0 0 0 0 4.5m0-4.5a2.25 2.25 0 0 1 0 4.5M4 19v-2.25m6-13.5V1m0 2.25a2.25 2.25 0 0 0 0 4.5m0-4.5a2.25 2.25 0 0 1 0 4.5M10 19V7.75m6 4.5V1m0 11.25a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM16 19v-2" />
              </svg>
            </Link>
          </>
        )}
        <label className="swap swap-rotate btn btn-ghost btn-circle">
          <input type="checkbox" onChange={toggleDarkMode} checked={darkModeState.darkMode} />
          <svg className="swap-on fill-current w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12Zm7-7a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5ZM5.64,7.05a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34Zm12,.29a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Zm-9,8a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z" /></svg>
          <svg className="swap-off fill-current w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.64,13a1,1,0,0,0-1.05-.14,8.05,8.05,0,0,1-3.37.73A8.15,8.15,0,0,1,9.08,5.49a8.59,8.59,0,0,1,.25-2A1,1,0,0,0,8,2.36,10.14,10.14,0,1,0,22,14.05,1,1,0,0,0,21.64,13Zm-9.5,6.69A8.14,8.14,0,0,1,7.08,5.22v.27A10.15,10.15,0,0,0,17.22,15.63a9.79,9.79,0,0,0,2.1-.22A8.11,8.11,0,0,1,12.14,19.73Z" /></svg>
        </label>
      </div>
    </div>
  );
};

export default Navbar;
