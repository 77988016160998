import React, { ReactNode } from 'react';

export interface StickyBannerProps {
  children: ReactNode;
  isHidden: boolean;
  setHidden: React.Dispatch<React.SetStateAction<boolean>>;
}

export const StickyBanner: React.FC<StickyBannerProps> = ({ children, isHidden, setHidden }) => {
  if (isHidden) {
    return null;
  }

  return (
    <div className="sticky top-0 left-0 z-20 w-full alert shadow-lg flex">
      <div className="flex items-center mx-auto">
        {children}
      </div>
      <div className="flex-none">
        <button
          type="button"
          onClick={() => setHidden(true)}
          className="btn btn-circle btn-outline btn-sm"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
          <span className="sr-only">Close banner</span>
        </button>
      </div>
    </div>
  );
};
