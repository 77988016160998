import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Jdenticon from 'react-jdenticon';
import { IdCard, ReceiptText, UserPen } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import ScrollableContentLayout from '../layouts/ScrollableContentLayout';
import { documentToUserDoc, UserDocument } from '../types/collections/User';
import FirebaseFirestoreService from '../services/database/strategies/FirebaseFirestoreService';
import { Breadcrumbs } from '../components/breadcrumbs/Breadcrumbs';
import { EditUserInformation } from '../components/page-components/settings/EditUserInformation';
import { EditTutorProfile } from '../components/page-components/settings/EditTutorProfile';

export interface SettingsProps {}

type UsersPages = 'Profile' | 'Tutor Card' | 'Invoices';

export const Settings: React.FC<SettingsProps> = () => {
  const { authState } = useAuth();
  const [userDoc, setUserDoc] = useState<UserDocument>();
  const navigate = useNavigate();
  const location = useLocation();

  const getPageFromSearchParams = (): UsersPages => {
    const searchParams = new URLSearchParams(location.search);
    const page = searchParams.get('page');
    if (page === 'Profile' || page === 'Tutor Card' || page === 'Invoices') {
      return page;
    }
    return 'Profile'; // Default to 'Profile' if no valid page is set
  };

  const [pageOpened, setPageOpened] = useState<UsersPages>(getPageFromSearchParams());

  useEffect(() => {
    if (authState.isAuthenticated !== undefined && !authState.isAuthenticated) {
      navigate('/authenticate');
    } else if (authState.user) {
      FirebaseFirestoreService.queryDocuments(
        'users',
        'uid',
        authState.user?.uid as string,
        'uid',
        1,
        undefined,
        (doc) => {
          if (doc.length > 0) {
            setUserDoc(documentToUserDoc(doc[0]));
          } else {
            navigate('/authenticate');
          }
        },
        () => {
          navigate('/authenticate');
        },
      );
    }
  }, [authState.user, authState.isAuthenticated, navigate]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', pageOpened);
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
  }, [pageOpened, navigate, location.pathname, location.search]);

  const handlePageChange = (page: UsersPages) => {
    setPageOpened(page);
  };

  return (
    <ScrollableContentLayout>
      {userDoc && (
        <section className="bg-base-200">
          <div className="container mx-auto px-4 py-8">
            <Breadcrumbs hrefs={['/']} pathNames={['Home', userDoc.fullName]} />
            <div className="flex flex-col sm:flex-row gap-4 sm:gap-16 items-start">
              <div className="avatar">
                <div className="w-24 rounded">
                  <Jdenticon size="96" value={userDoc.fullName} />
                </div>
              </div>
              <div>
                <h2 className="text-4xl font-bold mb-4">{userDoc.fullName}</h2>
                <div className="tabs tabs-boxed">
                  <button
                    type="button"
                    className={`tab ${pageOpened === 'Profile' ? 'tab-active' : ''}`}
                    onClick={() => handlePageChange('Profile')}
                  >
                    <UserPen className="w-4 h-4 mr-2" />
                    Profile
                  </button>
                  {userDoc.accountType === 'tutor' && (
                    <button
                      type="button"
                      className={`tab ${pageOpened === 'Tutor Card' ? 'tab-active' : ''}`}
                      onClick={() => handlePageChange('Tutor Card')}
                    >
                      <IdCard className="w-4 h-4 mr-2" />
                      Tutor Card
                    </button>
                  )}
                  <button
                    type="button"
                    className={`tab ${pageOpened === 'Invoices' ? 'tab-active' : ''}`}
                    onClick={() => handlePageChange('Invoices')}
                  >
                    <ReceiptText className="w-4 h-4 mr-2" />
                    Invoices
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <section className="bg-base-100">
        <div className="container mx-auto px-4 py-8">
          {pageOpened === 'Profile' && userDoc && <EditUserInformation userDoc={userDoc} />}
          {pageOpened === 'Tutor Card' && userDoc && <EditTutorProfile userDoc={userDoc} />}
          {pageOpened === 'Invoices' && <div>Invoices content goes here</div>}
        </div>
      </section>
    </ScrollableContentLayout>
  );
};
