import React from 'react';
import { Day, ScheduledEvent } from '../../types/Calendar';

export interface DayProps {
  day: Day;
  // eslint-disable-next-line react/require-default-props
  scheduledEvent?: ScheduledEvent;
}

export const DayButton: React.FC<DayProps> = ({ day, scheduledEvent }) => {
  const isToday = day.date.getDate() === new Date().getDate()
    && day.date.getMonth() === new Date().getMonth()
    && day.date.getFullYear() === new Date().getFullYear();

  const isScheduled = !!scheduledEvent;

  return (
    <div
      className="relative"
    >
      <button
        type="button"
        className={`w-8 h-8 border border-gray-200 rounded-lg shadow dark:border-gray-700 flex justify-center items-center dark:text-white 
          ${isToday ? 'bg-blue-200 dark:bg-blue-500' : ''}
          ${isScheduled ? 'bg-green-200 dark:bg-green-600' : 'hover:bg-gray-100 dark:hover:bg-gray-700 dark:bg-gray-800'}`}
      >
        {day.date.getDate()}
      </button>
    </div>
  );
};
