import React, { useEffect, useState } from 'react';
import { MessageCircleIcon } from 'lucide-react';
import { useAuth } from '../../../../contexts/AuthContext';
import {
  emptyTutorDocument, TutorDocument, TutorOnboardingState, getTutorOnboardingState,
} from '../../../../types/collections/Tutor';
import { UserDocument } from '../../../../types/collections/User';
import FirebaseFirestoreService from '../../../../services/database/strategies/FirebaseFirestoreService';
import { BreadcrumbStepper } from '../../../stepper/BreadcrumbStepper';
import { TutorInformation } from './TutorInformation';

export interface TutorOnboardingProps {
  userDoc: UserDocument;
}

const onboardingStateToStep: { [key in TutorOnboardingState]: number } = {
  'Account Created': 0,
  'Employment Information Complete': 1,
  'Account Reviewed': 2,
  'DBS Check Confirmed': 3,
  'Document Completed': 4,
};

export const TutorOnboarding: React.FC<TutorOnboardingProps> = ({ userDoc }) => {
  // Tutoring Information
  const [tutorDocument, setTutorDocument] = useState<TutorDocument>(emptyTutorDocument);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { authState } = useAuth();
  const [userId, setUserId] = useState('');
  const [onboardingStep, setOnboardingStep] = useState<TutorOnboardingState>('Account Created');

  useEffect(() => {
    if (authState.isAuthenticated && authState.user?.uid) {
      setUserId(authState.user.uid);
      FirebaseFirestoreService.queryDocuments(
        'tutors',
        'uid',
        authState.user.uid,
        'uid',
        1,
        undefined,
        (doc) => {
          if (doc.length > 0) {
            FirebaseFirestoreService.listenToDocument(
              'tutors',
              doc[0].id!,
              (tutorDoc) => {
                console.log(tutorDoc);
                setTutorDocument(tutorDoc as TutorDocument);
              },
              (error) => {
                console.error(error);
              },
            );
            setTutorDocument(doc[0] as TutorDocument);
          }
        },
        (error) => {
          console.log(error);
        },

      );
    }
  }, [authState]);

  useEffect(() => {
    if (tutorDocument !== emptyTutorDocument) {
      setOnboardingStep(getTutorOnboardingState(tutorDocument));
    }
  }, [tutorDocument]);

  useEffect(() => {
    setTutorDocument((prevState) => ({
      ...prevState,
      uid: userDoc.uid,
    }));
  }, [userDoc]);

  const createOrUpdateTutorDocument = (values: Partial<TutorDocument>) => {
    if (authState.isAuthenticated && authState.user?.uid) {
      setUserId(authState.user.uid);
      FirebaseFirestoreService.queryDocuments(
        'tutors',
        'uid',
        authState.user.uid,
        'uid',
        1,
        undefined,
        (doc) => {
          if (doc.length > 0) {
            console.log(doc[0].id);
            FirebaseFirestoreService.updateDocument(
              'tutors',
              doc[0].id!,
              values,
              () => { console.log('Successfully updated the userDocument'); },
              () => { console.log('Failed to update the userDocument'); },
            );
          } else {
            FirebaseFirestoreService.addDocument(
              'tutors',
              {
                ...tutorDocument,
                ...values,
              },
              (documentId) => {
                FirebaseFirestoreService.listenToDocument(
                  'tutors',
                  documentId,
                  (tutorDoc) => {
                    console.log(tutorDoc);
                    setTutorDocument(tutorDoc as TutorDocument);
                  },
                  (error) => {
                    console.error(error);
                  },
                );
                console.log('Created a new Tutor Document');
              },
              () => {
                console.log('Failed to add a new Tutor Document');
              },
            );
          }
        },
        (error) => {
          console.log(error);
        },

      );
    } else {
      console.error('User Not Authenticated');
    }
  };

  return (
    <div className="card w-full bg-neutral shadow-xl">
      <div className="card-body">
        <div className="flex flex-wrap justify-between items-center mb-4">
          <h2 className="card-title my-2">Tutor Onboarding</h2>
          <div className="flex flex-col sm:gap-10 items-center justify-center text-sm">
            <BreadcrumbStepper
              stepCompletedIndex={onboardingStateToStep[onboardingStep]}
              stepNames={[
                'Account Created',
                'Employment Information',
                'Account Confirmed',
                'DBS Check Confirmed',
              ]}
            />
          </div>
          <div className="flex items-center gap-2">
            <button
              type="button"
              onClick={() => setIsCollapsed(!isCollapsed)}
              className="btn btn-sm btn-ghost"
            >
              <MessageCircleIcon className="w-5 h-5" />
            </button>
            <button
              type="button"
              onClick={() => setIsCollapsed(!isCollapsed)}
              className="btn btn-sm btn-ghost"
            >
              {isCollapsed ? 'Expand' : 'Collapse'}
            </button>
          </div>
        </div>

        <div className={`space-y-4 transition-all duration-300 ease-in-out ${isCollapsed ? 'h-0 overflow-hidden' : 'h-auto'}`}>
          {/* Tutoring Onboarding Progress */}
          {onboardingStep === 'Account Created' && (
            <TutorInformation
              tutorDocument={tutorDocument}
              createOrUpdateTutorDocument={createOrUpdateTutorDocument}
              userId={userId}
            />
          )}

          {onboardingStep === 'Employment Information Complete' && (
            <div className="alert alert-info">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
              <div>
                <h3 className="font-bold">Processing your application</h3>
                <div className="text-xs">
                  Our team is currently reviewing your application.
                  Please make sure your email is valid. While you wait,
                  <a href="/settings?page=Tutor+Card" className="link link-primary">customise the tutor card</a>
                  {' '}
                  that will be shown to our students.
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
