/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
import React, { useEffect, useRef, useCallback } from 'react';

interface CanvasProps {
  // eslint-disable-next-line react/require-default-props
  gridSize?: number;
  currentTool: 'pen' | 'eraser';
}

export interface CanvasRef {
  renderText: (params: {
    text: string;
    x: number;
    y: number;
    size: number;
  }) => void;
  captureScreenshot: () => string | undefined;
  loadImage: (imageUrl: string) => void;
  getCanvas: () => HTMLCanvasElement | null;
}

const Canvas = React.forwardRef<CanvasRef, CanvasProps>(({ gridSize = 32, currentTool }, ref) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const isDrawing = useRef(false);
  const lastPos = useRef({ x: 0, y: 0 });

  // Separate useEffect for canvas initialization
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Initial canvas setup
    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      ctx.fillStyle = '#FFFFFF';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    };

    resizeCanvas();

    // Resize listener
    window.addEventListener('resize', resizeCanvas);
    return () => window.removeEventListener('resize', resizeCanvas);
  }, []); // Only run once on mount

  // Separate useEffect for drawing functionality
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const getCoordinates = (e: MouseEvent | TouchEvent) => {
      const canvas = canvasRef.current;
      if (!canvas) return { x: 0, y: 0 };

      const rect = canvas.getBoundingClientRect();

      if ('touches' in e) {
        // Touch event
        return {
          x: e.touches[0].clientX - rect.left,
          y: e.touches[0].clientY - rect.top,
        };
      }
      // Mouse event
      return {
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      };
    };

    const startDrawing = (e: MouseEvent | TouchEvent) => {
      e.preventDefault();
      isDrawing.current = true;
      const coords = getCoordinates(e);
      lastPos.current = coords;

      // Set drawing style
      ctx.strokeStyle = currentTool === 'pen' ? '#0000FF' : '#FFFFFF';
      ctx.lineWidth = currentTool === 'pen' ? 2 : 20;
      ctx.lineCap = 'round';
    };

    const draw = (e: MouseEvent | TouchEvent) => {
      if (!isDrawing.current) return;

      e.preventDefault();
      const coords = getCoordinates(e);

      ctx.beginPath();
      ctx.moveTo(lastPos.current.x, lastPos.current.y);
      ctx.lineTo(coords.x, coords.y);
      ctx.stroke();

      lastPos.current = coords;
    };

    const stopDrawing = () => {
      isDrawing.current = false;
    };

    // Add event listeners
    canvas.addEventListener('mousedown', startDrawing);
    canvas.addEventListener('mousemove', draw);
    canvas.addEventListener('mouseup', stopDrawing);
    canvas.addEventListener('mouseleave', stopDrawing);
    canvas.addEventListener('touchstart', startDrawing);
    canvas.addEventListener('touchmove', draw);
    canvas.addEventListener('touchend', stopDrawing);

    return () => {
      // Remove event listeners
      canvas.removeEventListener('mousedown', startDrawing);
      canvas.removeEventListener('mousemove', draw);
      canvas.removeEventListener('mouseup', stopDrawing);
      canvas.removeEventListener('mouseleave', stopDrawing);
      canvas.removeEventListener('touchstart', startDrawing);
      canvas.removeEventListener('touchmove', draw);
      canvas.removeEventListener('touchend', stopDrawing);
    };
  }, [currentTool]); // Only depends on currentTool

  const drawGrid = (ctx: CanvasRenderingContext2D, width: number, height: number) => {
    ctx.save();
    ctx.strokeStyle = '#ddd';
    ctx.lineWidth = 1;
    ctx.font = 'bold 16px Arial';

    // Draw vertical lines and column numbers (X axis)
    for (let x = 0; x < width; x += gridSize) {
      ctx.beginPath();
      ctx.moveTo(x, 0);
      ctx.lineTo(x, height);
      ctx.stroke();

      // Add background box and number for X coordinates
      const gridX = Math.floor(x / gridSize);
      const text = gridX.toString();
      const padding = 4;
      const textMetrics = ctx.measureText(text);
      const textWidth = textMetrics.width;

      // Draw background box
      ctx.fillStyle = 'rgba(255, 255, 255, 0.8)'; // Semi-transparent white
      ctx.fillRect(x + 2 - padding, 4, textWidth + (padding * 2), 20);
      ctx.strokeStyle = '#999'; // Light grey border
      ctx.strokeRect(x + 2 - padding, 4, textWidth + (padding * 2), 20);

      // Draw text
      ctx.fillStyle = '#000';
      ctx.fillText(text, x + 2, 20);
    }

    // Draw horizontal lines and row numbers (Y axis)
    for (let y = 0; y < height; y += gridSize) {
      ctx.beginPath();
      ctx.moveTo(0, y);
      ctx.lineTo(width, y);
      ctx.stroke();

      // Add background box and number for Y coordinates
      const gridY = Math.floor(y / gridSize);
      const text = gridY.toString();
      const padding = 4;
      const textMetrics = ctx.measureText(text);
      const textWidth = textMetrics.width;

      // Draw background box
      ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
      ctx.fillRect(2 - padding, y + 4, textWidth + (padding * 2), 20);
      ctx.strokeStyle = '#999';
      ctx.strokeRect(2 - padding, y + 4, textWidth + (padding * 2), 20);

      // Draw text
      ctx.fillStyle = '#000';
      ctx.fillText(text, 2, y + 20);
    }

    ctx.restore();
  };

  // Function to capture screenshot with grid
  const captureWithGrid = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = canvas.width;
    tempCanvas.height = canvas.height;
    const tempCtx = tempCanvas.getContext('2d');
    if (!tempCtx) return;

    // Fill temp canvas with white background
    tempCtx.fillStyle = '#FFFFFF';
    tempCtx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);
    // Draw original canvas content
    tempCtx.drawImage(canvas, 0, 0);

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Draw grid with numbers
    drawGrid(ctx, canvas.width, canvas.height);

    const screenshot = canvas.toDataURL('image/png');

    // Restore original canvas without grid
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = '#FFFFFF';
    ctx.fillRect(0, 0, canvas.width, canvas.height); // Ensure white background is restored
    ctx.drawImage(tempCanvas, 0, 0);

    return screenshot;
  };

  const getCanvas = () => canvasRef.current;

  const renderText = useCallback(({
    text, x, y, size,
  }: {
    text: string;
    x: number;
    y: number;
    size: number;
  }) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Set text properties with handwritten-style font and green color
    ctx.font = `${size}px Arial`; // Using Comic Sans as fallback handwritten font
    ctx.fillStyle = '#2E7D32'; // Forest green color

    // Add text
    ctx.fillText(text, x, y);
  }, []);

  const loadImage = useCallback((imageUrl: string) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const img = new Image();
    img.onload = () => {
      // Calculate maximum dimensions
      const maxWidth = gridSize * 20;
      const maxHeight = canvas.height - (gridSize * 2); // Leave margin at top and bottom

      // Calculate scales for both width and height constraints
      const scaleByWidth = maxWidth / img.width;
      const scaleByHeight = maxHeight / img.height;

      // Use the smaller scale to ensure image fits both constraints
      const scale = Math.min(scaleByWidth, scaleByHeight);

      // Calculate final dimensions
      const scaledWidth = img.width * scale;
      const scaledHeight = img.height * scale;

      // Position on the left side with margins
      const x = gridSize;
      const y = gridSize;

      // Draw the image
      ctx.drawImage(img, x, y, scaledWidth, scaledHeight);
    };
    img.src = imageUrl;
  }, [gridSize]);

  // Expose methods via ref
  React.useImperativeHandle(ref, () => ({
    renderText,
    captureScreenshot: () => captureWithGrid(),
    loadImage,
    getCanvas,
  }));

  return <canvas ref={canvasRef} className="absolute inset-0" />;
});

export default Canvas;
