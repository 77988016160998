import React from 'react';
import { LessonProposal } from '../../../types/collections/LessonProposal';
import { TutorProfileType } from '../../../types/TutorProfile';
import { TutorProfileCard } from '../../tutor-profile-card/TutorProfileCard';
import { UserDocument } from '../../../types/collections/User';

export interface ReviewLessonProps {
  previousStep: () => void,
  nextStep: () => void,
  lessonPlan: LessonProposal,
  selectedTutor: TutorProfileType | undefined,
  selectedTutorUserDoc: UserDocument | undefined,
}

export const ReviewLesson: React.FC<ReviewLessonProps> = ({
  previousStep, nextStep, lessonPlan, selectedTutor, selectedTutorUserDoc,
}) => (
  <div className="card flex-1 bg-base-100 shadow-xl">
    <div className="card-body">
      <h2 className="card-title text-3xl font-bold mb-4">Review</h2>
      <p className="mb-6">
        Review your application before it gets submitted!
      </p>

      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2">Lesson Details</h3>
        <div className="bg-base-200 p-4 rounded-lg">
          <p>
            <strong>Subject:</strong>
            {' '}
            {lessonPlan.subject}
          </p>
          <p>
            <strong>Level:</strong>
            {' '}
            {lessonPlan.level}
          </p>
          <p>
            <strong>Gender Preference:</strong>
            {' '}
            {lessonPlan.genderPreference}
          </p>
          <p>
            <strong>Additional Notes:</strong>
            {' '}
            {lessonPlan.tutorPreferenceNotes || 'None'}
          </p>
          <p>
            <strong>Lesson Time Notes:</strong>
            {' '}
            {lessonPlan.lessonTimeNotes || 'None'}
          </p>
        </div>
      </div>

      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2">Selected Tutor</h3>
        {selectedTutor && selectedTutorUserDoc ? (
          <TutorProfileCard
            tutorProfileType={selectedTutor}
            userDoc={selectedTutorUserDoc}
            pickTutor={() => {}}
          />
        ) : (
          <div className="alert alert-error">
            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
            <span>No tutor selected. Please go back and pick a tutor.</span>
          </div>
        )}
      </div>

      <div className="card-actions justify-between">
        <button
          type="button"
          onClick={previousStep}
          className="btn btn-outline"
        >
          Back
        </button>
        <button
          type="button"
          onClick={nextStep}
          className="btn btn-primary"
          disabled={!selectedTutor}
        >
          Submit Application
        </button>
      </div>
    </div>
  </div>
);
