import React, { useEffect, useState } from 'react';
import EngagedLayout from '../../../layouts/EngagedLayout';
import { useAuth } from '../../../contexts/AuthContext';
import { AccountType } from './steps/AccountType';
import { UserInformation } from './steps/UserInformation';
import { Subjects } from './steps/Subjects';
import { OnboardingComplete } from './steps/OnboardingComplete';
import { UserDocument } from '../../../types/collections/User';
import FirebaseFirestoreService from '../../../services/database/strategies/FirebaseFirestoreService';
import StripePaymentService from '../../../services/payments/strategies/StripePaymentService';
import { useDarkMode } from '../../../contexts/DarkModeContext';

const OnboardingLanding = () => {
  const TOTAL_NUMBER_OF_STEPS = 6;
  const [step, setStep] = useState(0);
  const {
    authState, logout, checkEmailVerification, sendVerificationEmail,
  } = useAuth();
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [userDocument, setUserDocument] = useState<UserDocument>({
    accountType: 'student',
    uid: authState.user?.uid || '',
    fullName: authState.user?.name || '',
    phoneNumber: '',
    email: authState.user?.email || '',
    addressFirstLine: '',
    addressSecondLine: '',
    city: '',
    postcode: '',
    gcseYear: '',
    subjects: [],
    id: '',
  });

  const { darkModeState, toggleDarkMode } = useDarkMode();
  const [error, setError] = useState('');

  const [verificationEmailSent, setVerificationEmailSent] = useState(false);
  const [isCheckingVerification, setIsCheckingVerification] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState('');

  useEffect(() => {
    setUserDocument((prevUserDoc) => ({
      ...prevUserDoc,
      uid: authState.user?.uid ? authState.user?.uid : '',
    }));
    checkEmailVerification((isVerified) => {
      setIsEmailVerified(isVerified);
      if (isVerified) {
        setStep(1); // Skip email verification step if already verified
      }
    });
  }, [authState, checkEmailVerification]);

  const handleSendVerificationEmail = () => {
    sendVerificationEmail(
      () => {
        setVerificationEmailSent(true);
        setVerificationMessage('Verification email sent. Please check your inbox and verify your email before continuing.');
      },
      (verificationError) => {
        console.error('Error sending verification email:', verificationError);
        setError('Failed to send verification email. Please try again or contact support at dev@tutr.ai');
      },
    );
  };

  const handleCheckVerification = () => {
    setIsCheckingVerification(true);
    checkEmailVerification(
      (isVerified) => {
        setIsEmailVerified(isVerified);
        if (isVerified) {
          setStep(1);
          setVerificationMessage('Email verified successfully. You can now proceed with the onboarding process.');
        } else {
          setVerificationMessage('Your email is not verified yet. Please check your inbox and click the verification link.');
        }
        setIsCheckingVerification(false);
      },
      (verificationError) => {
        console.error('Error checking email verification:', verificationError);
        setError('Failed to check email verification status. Please try again later.');
        setIsCheckingVerification(false);
      },
    );
  };

  const createStripeCustomer = async (user: UserDocument) => {
    try {
      await StripePaymentService.createCustomer(
        {
          metadata: { uid: user.uid },
          name: user.fullName,
          email: user.email,
          phone: user.phoneNumber,
          address: {
            line1: user.addressFirstLine,
            line2: user.addressSecondLine,
            city: user.city,
            postal_code: user.postcode,
            country: 'GB',
          },
        },
        async (customerId) => {
          console.log('Stripe customer created successfully:', customerId);
          // Update the user's document in Firestore with the new stripeCustomerId
          try {
            await FirebaseFirestoreService.updateDocument(
              'users',
              user.uid,
              { stripeCustomerId: customerId },
              () => {
                console.log('User document updated with Stripe customer ID');
                setStep(Math.min(step + 1, TOTAL_NUMBER_OF_STEPS - 1));
              },
              (updateError) => {
                console.error('Error updating user document:', updateError);
                // Handle error (you might want to show an error message to the user)
              },
            );
          } catch (updateError) {
            console.error('Error updating user document:', updateError);
            // Handle error (you might want to show an error message to the user)
          }
        },
        (createError) => {
          console.error('Error creating Stripe customer:', createError.message);
          // Handle error (you might want to show an error message to the user)
        },
      );
    } catch (stripeError) {
      console.error('Error in createStripeCustomer:', stripeError);
      // Handle error (you might want to show an error message to the user)
    }
  };

  const validateForm = () => {
    let valid = true;

    if (userDocument.fullName === '') {
      valid = false;
    }

    const pattern = /^\+?[0-9]{2}\s?0?[0-9]{5}\s?[0-9]{5}$/;
    if (!pattern.test(userDocument.phoneNumber)) {
      valid = false;
    }

    const emailPattern = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/;
    if (!emailPattern.test(userDocument.email)) {
      valid = false;
    }

    if (
      !userDocument.gcseYear
        || userDocument.gcseYear === ''
      || parseInt(userDocument.gcseYear, 10) <= 1960
        || parseInt(userDocument.gcseYear, 10) >= 2060) {
      valid = false;
    }

    if (userDocument.accountType === 'tutor' && (!userDocument.addressFirstLine || userDocument.addressFirstLine === '')) {
      valid = false;
    }

    if (userDocument.accountType === 'tutor' && (userDocument.city === '' || !userDocument.city)) {
      valid = false;
    }

    if (userDocument.accountType === 'tutor' && (!userDocument.postcode || userDocument.postcode === '')) {
      valid = false;
    }

    return valid;
  };

  return (
    <EngagedLayout>
      <nav className="navbar bg-base-100 shadow-lg fixed top-0 w-full z-10">
        <div className="flex-1">
          <a href="/" className="btn btn-ghost normal-case text-xl">Tütr.ai</a>
        </div>
        <div className="flex-none">
          {authState.isAuthenticated && (
            <button
              type="button"
              className="btn btn-error mr-2"
              onClick={() => { logout(() => { window.location.href = '/'; }, () => { window.location.href = '/'; }); }}
            >
              Signout
            </button>
          )}
          <button
            className="btn btn-square btn-ghost"
            type="button"
            onClick={() => { toggleDarkMode(); }}
          >
            {darkModeState.darkMode ? (
              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" fillRule="evenodd" clipRule="evenodd" /></svg>
            ) : (
              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" /></svg>
            )}
          </button>
        </div>
      </nav>

      <section className="pt-20 pb-10 bg-base-100 w-[80vw] h-[80vh]">
        <div className="container mx-auto px-4 h-full">
          <div className="flex gap-2 items-center w-4/5 mx-auto mb-8">
            <progress
              className="progress progress-primary w-full"
              value={step + 1}
              max={TOTAL_NUMBER_OF_STEPS}
            />
          </div>

          <div className="card bg-base-200 shadow-xl max-w-3xl mx-auto h-full text-black dark:text-white">
            <div className="card-body">
              {step === 0 && (
                <div className="text-center">
                  <h2 className="card-title text-3xl font-bold mb-4 justify-center">Verify Your Email</h2>
                  <p className="mb-6">
                    {isEmailVerified
                      ? 'Your email has been verified. You can proceed with the onboarding process.'
                      : `Please verify your email address (${authState.user?.email || 'Email Not Found...'}) to continue with the onboarding process.`}
                  </p>
                  {!isEmailVerified && (
                    <div className="flex flex-col items-center space-y-4">
                      {!verificationEmailSent ? (
                        <button
                          type="button"
                          onClick={handleSendVerificationEmail}
                          className="btn btn-primary"
                        >
                          Send Verification Email
                        </button>
                      ) : (
                        <>
                          <p className="text-success">{verificationMessage}</p>
                          <button
                            type="button"
                            onClick={handleCheckVerification}
                            disabled={isCheckingVerification}
                            className="btn btn-success"
                          >
                            {isCheckingVerification ? 'Checking...' : 'Check Verification Status'}
                          </button>
                        </>
                      )}
                      {error && <p className="text-error">{error}</p>}
                    </div>
                  )}
                </div>
              )}

              {step === 1 && (
                <div className="mx-auto max-w-screen-sm text-center">
                  <h2 className="mb-4 text-4xl font-extrabold leading-tight text-gray-900 dark:text-white">Welcome to Tütr.ai</h2>
                  <p className="mb-6 font-light text-gray-500 dark:text-gray-400 md:text-lg">Walk through our quick onboarding to create your account!</p>
                </div>
              )}

              {step === 2 && (
                <AccountType
                  setUserDocument={setUserDocument}
                  userDocument={userDocument}
                  nextStep={() => {
                    setStep(3);
                  }}
                />
              )}

              {step === 3 && (
                <UserInformation
                  setUserDocument={setUserDocument}
                  userDocument={userDocument}
                />
              )}

              {step === 4 && (
                <Subjects
                  setUserDocument={setUserDocument}
                  userDocument={userDocument}
                />
              )}

              {step === 5 && <OnboardingComplete userDocument={userDocument} />}

            </div>
          </div>

          <div className="flex justify-between items-center w-4/5 mx-auto mt-8">
            <button
              type="button"
              onClick={() => {
                setStep(Math.max(step - 1, 0));
              }}
              disabled={step === 0 || step === TOTAL_NUMBER_OF_STEPS - 1}
              className="btn btn-secondary"
            >
              Previous Step
            </button>
            {step < TOTAL_NUMBER_OF_STEPS - 2 && step !== 0 && (
              <button
                type="button"
                onClick={() => {
                  setStep(Math.min(step + 1, TOTAL_NUMBER_OF_STEPS - 1));
                }}
                disabled={(step === 0 && !isEmailVerified) || (step === 3 && !validateForm())}
                className="btn btn-primary"
              >
                Next Step
              </button>
            )}
            {step === 4 && (
              <button
                type="button"
                onClick={() => {
                  if (authState.user) {
                    FirebaseFirestoreService.updateDocument(
                      'users',
                      authState.user.uid,
                      userDocument,
                      () => {
                        createStripeCustomer({ ...userDocument, id: authState.user!.uid });
                        setStep(Math.min(step + 1, TOTAL_NUMBER_OF_STEPS - 1));
                      },
                      () => {
                        // console.log('Failed to get anywhere');
                      },
                    );
                  }
                }}
                className="btn btn-primary"
              >
                Submit User Information
              </button>
            )}
            {step === TOTAL_NUMBER_OF_STEPS - 1 && (
              <button
                type="button"
                onClick={() => {
                  window.location.href = '/';
                }}
                className="btn btn-success"
              >
                Continue to Landing
              </button>
            )}
          </div>
        </div>
      </section>
    </EngagedLayout>
  );
};

export default OnboardingLanding;
