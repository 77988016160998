import React, { useState } from 'react';
import Jdenticon from 'react-jdenticon';
import Markdown from 'react-markdown';
import { UserDocument } from '../../types/collections/User';
import { TutorProfileType, TimeRange } from '../../types/TutorProfile';

export interface TimeRangeChartProps {
  day: string;
  timeRange: TimeRange;
}

const TimeRangeChart: React.FC<TimeRangeChartProps> = ({ day, timeRange }) => {
  const [hover, setHover] = useState(false);

  const timeToMinutes = (time: string) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };

  const totalMinutesInDay = 24 * 60;
  const startMinutes = timeToMinutes(timeRange.startTime);
  const endMinutes = timeToMinutes(timeRange.endTime);
  const durationMinutes = endMinutes - startMinutes;

  const startPercentage = (startMinutes / totalMinutesInDay) * 100;
  const durationPercentage = (durationMinutes / totalMinutesInDay) * 100;

  return (
    <div className="w-[25px] h-full relative">
      <div
        className="w-[25px] h-[90%] rounded-md bg-gray-200 border border-gray-400 relative"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div
          className="absolute bg-primary rounded-md"
          style={{
            top: `${startPercentage}%`,
            height: `${durationPercentage}%`,
            width: '100%',
          }}
        />
      </div>
      {hover && (
        <div
          className="absolute bg-white text-primary-content p-4 rounded-md shadow-lg z-20 border border-gray-500 flex flex-col"
          style={{
            top: `${startPercentage}%`,
            transform: 'translateY(-100%)',
            left: '110%',
          }}
        >
          <p className="text-gray-500 text-sm">
            Start:
            {timeRange.startTime}
          </p>
          <p className="text-gray-500 text-sm">
            End:
            {timeRange.endTime}
          </p>
        </div>
      )}
      <p className="text-[10px] font-light text-center">{day}</p>
    </div>
  );
};

interface TutorProfileCardProps {
  userDoc: UserDocument;
  tutorProfileType: TutorProfileType;
  pickTutor: (tutor: TutorProfileType) => void;
  selected?: boolean;
}

export const TutorProfileCard: React.FC<TutorProfileCardProps> = ({
  userDoc,
  tutorProfileType,
  pickTutor,
  selected = false,
}) => {
  const getAveragePricing = () => {
    const allPrices = Object.values(tutorProfileType.pricing)
      .flatMap((subject) => Object.values(subject));
    const sum = allPrices.reduce((acc, price) => acc + price, 0);
    return (sum / allPrices.length).toFixed(2);
  };

  return (
    <div className={`card bg-base-100 shadow-xl ${selected ? 'border-2 bg-primary text-primary-content' : ''}`}>
      <div className="card-body">
        <div className="flex items-center gap-4">
          <div className="avatar">
            <div className="w-24 rounded-full">
              <Jdenticon size="96" value={userDoc.fullName} />
            </div>
          </div>
          <div>
            <h2 className="card-title">{userDoc.fullName}</h2>
            <p className="text-lg font-bold">
              £
              {getAveragePricing()}
              {' '}
              average per lesson
            </p>
            <p>
              {tutorProfileType.activeStudents}
              {' '}
              active students
            </p>
            <p>
              {tutorProfileType.experienceYears}
              {' '}
              years of experience
            </p>
          </div>
        </div>

        <div className="mt-4">
          <p>
            <strong>Subjects:</strong>
            {' '}
            {Object.keys(tutorProfileType.pricing).join(', ')}
          </p>
          <Markdown className="mt-2 prose dark:prose-invert text-primary-content">{tutorProfileType.description}</Markdown>
        </div>

        <div className="card-actions justify-end mt-4">
          <label htmlFor={`tutor-modal-${userDoc.uid}`} className="btn btn-primary">View Details</label>
          <button type="button" className="btn btn-outline" onClick={() => pickTutor(tutorProfileType)}>
            Pick Tutor
          </button>
        </div>
      </div>

      {/* Modal */}
      <input type="checkbox" id={`tutor-modal-${userDoc.uid}`} className="modal-toggle" />
      <div className="modal text-neutral-content">
        <div className="modal-box w-11/12 max-w-5xl">
          <h3 className="font-bold text-lg">
            {userDoc.fullName}
            {' '}
            - Detailed Profile
          </h3>
          <div className="py-4">
            <Markdown className="prose">{tutorProfileType.biography}</Markdown>

            <h4 className="font-bold mt-4">Pricing</h4>
            {Object.entries(tutorProfileType.pricing).map(([subject, levels]) => (
              <div key={subject} className="mt-2">
                <h5 className="font-semibold">{subject}</h5>
                <ul>
                  {Object.entries(levels).map(([level, price]) => (
                    <li key={level}>
                      {level}
                      : £
                      {price}
                    </li>
                  ))}
                </ul>
              </div>
            ))}

            <h4 className="font-bold mt-4">Availability</h4>
            <div className="flex gap-2 h-[70px] items-center my-2">
              {Object.entries(tutorProfileType.availability).map(([day, timeRange]) => (
                <TimeRangeChart key={day} day={day.slice(0, 3)} timeRange={timeRange} />
              ))}
            </div>
          </div>
          <div className="modal-action">
            <label htmlFor={`tutor-modal-${userDoc.uid}`} className="btn">Close</label>
            <button type="button" className="btn btn-primary" onClick={() => pickTutor(tutorProfileType)}>
              Pick Tutor
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

TutorProfileCard.defaultProps = {
  selected: false,
};

export default TutorProfileCard;
