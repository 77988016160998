import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Lesson } from '../../types/collections/Lesson';
import LessonCard from '../page-components/landing/LessonCard/LessonCard';
import FirebaseFirestoreService from '../../services/database/strategies/FirebaseFirestoreService';

interface LessonGroup {
    lessons: Lesson[];
    title: string;
    allowNotes: boolean;
    addNote: (lessonId: string, note: string) => void;
  }

const RenderLessonGroup: React.FC<LessonGroup> = ({
  lessons, title, allowNotes, addNote,
}) => {
  if (lessons.length > 0) {
    return (
      <div className="mb-8">
        <h2 className="font-bold mb-4">{title}</h2>
        <div className="space-y-4">
          {lessons.map((lesson) => (
            <div key={lesson.startDate + lesson.time} className="collapse collapse-arrow bg-base-200">
              <input type="checkbox" />
              <div className="collapse-title text-xl font-medium">
                {lesson.subject}
                {' '}
                -
                {new Date(lesson.startDate).toLocaleDateString()}
              </div>
              <div className="collapse-content">
                <LessonCard lesson={lesson} />
                {allowNotes && (
                  <div className="mt-4">
                    <textarea
                      className="textarea textarea-bordered w-full"
                      placeholder="Add notes here..."
                      defaultValue={lesson.notes || ''}
                      onChange={(e) => {
                        console.assert(lesson.id, 'Lesson does not have an ID.');
                        addNote(lesson.id!, e.target.value);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
  return <p> </p>;
};

interface SkeletonLessonProps {}

const SkeletonLesson: React.FC<SkeletonLessonProps> = () => (
  <div role="status" className="animate-pulse card w-full bg-base-200 shadow-xl">
    <div className="card-body">
      <div className="h-2.5 bg-base-300 rounded-full w-48 mb-4" />
      <div className="h-2 bg-base-300 rounded-full max-w-[360px] mb-2.5" />
      <div className="h-2 bg-base-300 rounded-full mb-2.5" />
      <div className="h-2 bg-base-300 rounded-full max-w-[330px] mb-2.5" />
      <div className="h-2 bg-base-300 rounded-full max-w-[300px] mb-2.5" />
      <div className="h-2 bg-base-300 rounded-full max-w-[360px]" />
    </div>
  </div>
);

export interface LessonListProps {
  isStudent: boolean;
}

export const LessonsList: React.FC<LessonListProps> = ({ isStudent }) => {
  const { authState } = useAuth();
  const [futureLessons, setFutureLessons] = useState<Lesson[]>([]);
  const [previousLessons, setPreviousLessons] = useState<Lesson[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchLessons = async () => {
      if (authState.user?.uid) {
        const loadedLessons: Lesson[] = [];
        const now = new Date();

        const fetchLessonsForField = (field: string) => new Promise<void>((resolve) => {
          FirebaseFirestoreService.queryDocuments(
            'lessons',
            field,
            authState.user!.uid,
            'startDate',
            20,
            undefined,
            (documents) => {
              loadedLessons.push(...documents.map((elem) => elem as Lesson));
              resolve();
            },
            (error) => {
              console.error('Error fetching lessons:', error);
              resolve();
            },
          );
        });

        await Promise.all([
          fetchLessonsForField('participant1Id'),
          fetchLessonsForField('participant2Id'),
        ]);

        const sortedLessons = loadedLessons.sort(
          (a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
        );
        setFutureLessons(sortedLessons.filter((lesson) => new Date(lesson.startDate) >= now));
        setPreviousLessons(sortedLessons.filter((lesson) => new Date(lesson.startDate) < now));
        setLoading(false);
      }
    };

    fetchLessons();
  }, [authState.user, isStudent]);

  const addNote = (lessonId: string, note: string) => {
    FirebaseFirestoreService.updateDocument(
      'lessons',
      lessonId,
      { notes: note },
      () => {
        console.log('Note added successfully');
        // Optionally, update the local state to reflect the change
      },
      (error) => {
        console.error('Error adding note:', error);
      },
    );
  };

  if (loading) {
    return <SkeletonLesson />;
  }

  if (futureLessons.length === 0 && previousLessons.length === 0) {
    return (
      <div className="alert alert-info">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
        <span>No lessons scheduled...</span>
      </div>
    );
  }

  return (
    <div>
      <RenderLessonGroup
        lessons={futureLessons
          .filter((lesson) => new Date(lesson.startDate)
            .toDateString() === new Date().toDateString())}
        title="Lessons Due Today"
        allowNotes={false}
        addNote={addNote}
      />
      <RenderLessonGroup
        lessons={futureLessons
          .filter((lesson) => new Date(lesson.startDate)
            .toDateString() !== new Date().toDateString())}
        title="Future Lessons"
        allowNotes={false}
        addNote={addNote}
      />
      <RenderLessonGroup
        lessons={previousLessons}
        title="Previous Lessons"
        allowNotes
        addNote={addNote}
      />
    </div>
  );
};
