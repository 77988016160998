import React, { useEffect, useState } from 'react';
import { CVUpload } from './steps/CVUpload';
import { IDConfirmation } from './steps/IDConfirmation';
import { ReferencesUpload } from './steps/ReferencesUpload';
import { UniformStatus } from './steps/UniformStatus';
import { EmploymentStatus } from './steps/EmploymentStatus';
import { TutorDocument } from '../../../../types/collections/Tutor';

interface StepsCompletedIconProps {
  isComplete: boolean,
}
const StepCompletedIcon:React.FC<StepsCompletedIconProps> = ({ isComplete }) => {
  if (!isComplete) {
    return (
      <span className="badge badge-outline badge-info">Pending</span>
    );
  }
  return (
    <span className="badge badge-info">Completed</span>
  );
};

export interface TutorInformationProps {
  userId: string,
  tutorDocument: TutorDocument,
  createOrUpdateTutorDocument: (values: Partial<TutorDocument>) => void;
}

export const TutorInformation: React.FC<TutorInformationProps> = ({
  userId,
  tutorDocument,
  createOrUpdateTutorDocument,
}) => {
  const [stepsHidden, setStepsHidden] = useState({
    cvUpload: true,
    idConfirmation: true,
    references: true,
    uniform: true,
    agreeToEmploymentStatus: true,
  });

  const [stepsCompleted, setStepsCompleted] = useState({
    cvUpload: false,
    idConfirmation: false,
    references: false,
    uniform: false,
    agreeToEmploymentStatus: false,
  });

  useEffect(() => {
    setStepsCompleted((prevState) => ({
      ...prevState,
      cvUpload: tutorDocument.cvUploadedLocation !== '',
      idConfirmation: tutorDocument.identificationUploadedLocation !== '',
      references: tutorDocument.reference1Email !== '' && tutorDocument.reference2Email !== '',
      uniform: tutorDocument.uniformAgreementDate !== undefined,
      agreeToEmploymentStatus: tutorDocument.employmentStatusAgreementDate !== undefined,
    }));
  }, [tutorDocument]);

  return (
    <div className="space-y-4">
      {/* CV Upload */}
      <div className="collapse collapse-arrow bg-base-200">
        <input type="checkbox" checked={!stepsHidden.cvUpload} onChange={() => setStepsHidden((prev) => ({ ...prev, cvUpload: !prev.cvUpload }))} />
        <div className="collapse-title text-xl font-medium flex items-center">
          <StepCompletedIcon isComplete={stepsCompleted.cvUpload} />
          <span className="ml-2">CV Upload</span>
        </div>
        <div className="collapse-content">
          <CVUpload
            cvUpload={stepsHidden.cvUpload}
            userId={userId}
            tutorDoc={tutorDocument}
            createOrUpdateTutorDocument={createOrUpdateTutorDocument}
          />
        </div>
      </div>

      {/* Identification Confirmation */}
      <div className="collapse collapse-arrow bg-base-200">
        <input type="checkbox" checked={!stepsHidden.idConfirmation} onChange={() => setStepsHidden((prev) => ({ ...prev, idConfirmation: !prev.idConfirmation }))} />
        <div className="collapse-title text-xl font-medium flex items-center">
          <StepCompletedIcon isComplete={stepsCompleted.idConfirmation} />
          <span className="ml-2">Identification Upload</span>
        </div>
        <div className="collapse-content">
          <IDConfirmation
            idConfirmed={stepsHidden.idConfirmation}
            userId={userId}
            tutorDoc={tutorDocument}
            createOrUpdateTutorDocument={createOrUpdateTutorDocument}
          />
        </div>
      </div>

      {/* Tutor References */}
      <div className="collapse collapse-arrow bg-base-200">
        <input type="checkbox" checked={!stepsHidden.references} onChange={() => setStepsHidden((prev) => ({ ...prev, references: !prev.references }))} />
        <div className="collapse-title text-xl font-medium flex items-center">
          <StepCompletedIcon isComplete={stepsCompleted.references} />
          <span className="ml-2">Tutor References</span>
        </div>
        <div className="collapse-content">
          <ReferencesUpload
            referencesUploaded={stepsHidden.references}
            tutorDoc={tutorDocument}
            createOrUpdateTutorDocument={createOrUpdateTutorDocument}
          />
        </div>
      </div>

      {/* Uniform Status */}
      <div className="collapse collapse-arrow bg-base-200">
        <input type="checkbox" checked={!stepsHidden.uniform} onChange={() => setStepsHidden((prev) => ({ ...prev, uniform: !prev.uniform }))} />
        <div className="collapse-title text-xl font-medium flex items-center">
          <StepCompletedIcon isComplete={stepsCompleted.uniform} />
          <span className="ml-2">Uniform Information</span>
        </div>
        <div className="collapse-content">
          <UniformStatus
            uniformComplete={stepsHidden.uniform}
            tutorDoc={tutorDocument}
            createOrUpdateTutorDocument={createOrUpdateTutorDocument}
          />
        </div>
      </div>

      {/* Employment Status */}
      <div className="collapse collapse-arrow bg-base-200">
        <input type="checkbox" checked={!stepsHidden.agreeToEmploymentStatus} onChange={() => setStepsHidden((prev) => ({ ...prev, agreeToEmploymentStatus: !prev.agreeToEmploymentStatus }))} />
        <div className="collapse-title text-xl font-medium flex items-center">
          <StepCompletedIcon isComplete={stepsCompleted.agreeToEmploymentStatus} />
          <span className="ml-2">Employment Status</span>
        </div>
        <div className="collapse-content">
          <EmploymentStatus
            employmentComplete={stepsHidden.agreeToEmploymentStatus}
            tutorDoc={tutorDocument}
            createOrUpdateTutorDocument={createOrUpdateTutorDocument}
          />
        </div>
      </div>
    </div>
  );
};
