import React from 'react';
import ScrollableContentLayout from '../layouts/ScrollableContentLayout';

export interface SyllabusProps {

}

export const Syllabus: React.FC<SyllabusProps> = () => (
  <ScrollableContentLayout>
    <p>Syllabus Information Here</p>
  </ScrollableContentLayout>
);
