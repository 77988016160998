import React, { useState } from 'react';
import ScrollableContentLayout from '../../../layouts/ScrollableContentLayout';
import { UserDocument } from '../../../types/collections/User';
import { StickyBanner } from '../../banners/StickyBanner';
import { TutorOnboarding } from './tutor-onboarding/TutorOnboarding';
import { Calendar } from '../../calendar/Calendar';
import { StudentDocumentChecks } from './student-docs-check/StudentDocumentChecks';
import { NavigationCards } from './navigation-cards/NavigationCards';

export interface AuthenticatedLandingProps {
    userDoc: UserDocument;
}
const AuthenticatedLanding :React.FC<AuthenticatedLandingProps> = ({ userDoc }) => {
  const [hideStickyBanner, setHideStickyBanner] = useState<boolean>(false);
  return (
    <ScrollableContentLayout>
      <div className="relative bg-base-10">

        {/* Sticky Banner */}
        <StickyBanner isHidden={hideStickyBanner} setHidden={setHideStickyBanner}>
          <p className="flex items-center text-sm font-normal dark:text-white">
            <span
              className="inline-flex p-1 me-3 bg-gray-200 rounded-full dark:bg-gray-600 w-6 h-6 items-center justify-center flex-shrink-0"
            >
              <svg
                className="w-3 h-3 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 19"
              >
                <path
                  d="M15 1.943v12.114a1 1 0 0 1-1.581.814L8 11V5l5.419-3.871A1 1 0 0 1 15 1.943ZM7 4H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2v5a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V4ZM4 17v-5h1v5H4ZM16 5.183v5.634a2.984 2.984 0 0 0 0-5.634Z"
                />
              </svg>
              <span className="sr-only">Light bulb</span>
            </span>
            <span>
              Hi
              {' '}
              {userDoc.fullName.split(' ')[0]}
              {'! '}
              We&apos;re still in development, any feedback contact at
              <a
                href="mailto:dev@tutr.ai?subject=T%C3%BCtr.ai%20Error%2FFeature&body=Hi%20guys%2C%0D%0A%0D%0AI%20have%20the%20following%20feature%20requests%3A%0D%0A1.%0D%0A2.%0D%0A%0D%0A%0D%0AI%20have%20spotted%20the%20following%20bugs%3A%0D%0A1.%0D%0A2.%0D%0A%0D%0A%0D%0AWhat%20could%20we%20do%20to%20make%20your%20experience%20better%3F%0D%0A%0D%0ABest%2C%0D%0AYour%20name."
                className="inline font-medium text-blue-600 underline underline-offset-2 decoration-600 dark:decoration-500 decoration-solid hover:no-underline"
              >
                {' '}
                dev@tutr.ai
              </a>
            </span>
          </p>
        </StickyBanner>

        <div className="p-2 bg-base-100">
          <div className="px-4 mx-auto max-w-screen-xl lg:py-8 lg:px-6 gap-2">
            {/* Tutoring Onboarding Checks */}
            {userDoc && userDoc.accountType === 'tutor' && <TutorOnboarding userDoc={userDoc} />}
            {/* Student Onboarding Checks */}
            {userDoc && userDoc.accountType === 'student' && <StudentDocumentChecks userDoc={userDoc} />}
            {/* Temporary Spacing */}
            <div className="py-2 flex justify-center items-center">
              <Calendar userDoc={userDoc} />
            </div>
            {userDoc && <NavigationCards isStudent={userDoc.accountType === 'student'} />}
          </div>
        </div>
      </div>
    </ScrollableContentLayout>
  );
};

export default AuthenticatedLanding;
