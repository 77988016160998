import React from 'react';
import Jdenticon from 'react-jdenticon';
import { Chat, Message } from '../../../../types/collections/Chats';
import { useAuth } from '../../../../contexts/AuthContext';

interface ChatBubbleProps {
  chat: Chat;
  message: Message;
}

const ChatBubble: React.FC<ChatBubbleProps> = ({ chat, message }) => {
  const { authState } = useAuth();

  const renderMessageContent = () => (
    <>
      <p className="text-sm py-2.5">
        {message.text}
      </p>
      {message.file && (
        <div className="flex items-start my-2.5 bg-base-200 rounded-xl p-2">
          <div className="me-2 px-2">
            <span className="flex items-center gap-2 text-sm text-base-content font-medium pb-2">
              {message.file.fileName}
            </span>
            <span className="flex text-xs text-base-content/70 gap-2">
              {message.file.fileSize}
              {' '}
              •
              {message.file.fileType}
            </span>
          </div>
          <button
            onClick={() => window.open(message.file?.fileUrl, '_blank')}
            className="btn btn-circle btn-sm"
            type="button"
          >
            <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M14.707 7.793a1 1 0 0 0-1.414 0L11 10.086V1.5a1 1 0 0 0-2 0v8.586L6.707 7.793a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.416 0l4-4a1 1 0 0 0-.002-1.414Z" />
              <path d="M18 12h-2.55l-2.975 2.975a3.5 3.5 0 0 1-4.95 0L4.55 12H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2Zm-3 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
            </svg>
          </button>
        </div>
      )}
      {message.image && (
        <div className="relative my-2.5 group">
          <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg flex items-center justify-center">
            <button
              onClick={() => window.open(message.image?.imageUrl, '_blank')}
              className="btn btn-circle btn-sm"
              type="button"
            >
              <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 18">
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3"
                />
              </svg>
            </button>
          </div>
          <img src={message.image.imageUrl} className="rounded-lg" alt={message.image.caption || 'Image'} />
          {message.image.caption && (
            <p className="mt-2 text-sm text-base-content/70">{message.image.caption}</p>
          )}
        </div>
      )}
    </>
  );

  const isCurrentUser = authState.user && message.sender === authState.user.uid;
  const senderName = message.sender === chat.participant1Id
    ? chat.participant1Name : chat.participant2Name;

  return (
    <div className={`chat ${isCurrentUser ? 'chat-end' : 'chat-start'}`}>
      <div className="chat-image avatar">
        <div className="w-10 rounded-full">
          <Jdenticon size="40" value={senderName} />
        </div>
      </div>
      <div className="chat-header">
        {senderName}
        <time className="text-xs opacity-50 ml-1">
          {new Date(message.timestamp).toLocaleString()}
        </time>
      </div>
      <div className={`chat-bubble ${isCurrentUser ? 'chat-bubble-primary' : ''}`}>
        {renderMessageContent()}
      </div>
      <div className="chat-footer opacity-50">
        {message.status}
      </div>
    </div>
  );
};

export default ChatBubble;
