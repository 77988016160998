import React, { useState } from 'react';
import { UserDocument } from '../../../types/collections/User';
import { SubjectMapping } from '../../../types/Subjects';
import FirebaseFirestoreService from '../../../services/database/strategies/FirebaseFirestoreService';

export interface EditUserInformationProps {
  userDoc: UserDocument;
}

export const EditUserInformation: React.FC<EditUserInformationProps> = ({ userDoc }) => {
  const [localUserDoc, setLocalUserDoc] = useState<UserDocument>(userDoc);
  const [errors, setErrors] = useState({
    fullName: '',
    addressFirstLine: '',
    addressSecondLine: '',
    phoneNumber: '',
    city: '',
    postCode: '',
    email: '',
    gcseYear: '',
  });

  const validateForm = (newDocument: UserDocument) => {
    const valid = true;
    const errorForm = {
      fullName: '',
      addressFirstLine: '',
      addressSecondLine: '',
      phoneNumber: '',
      city: '',
      postCode: '',
      email: '',
      gcseYear: '',
    };

    if (newDocument.fullName === '') {
      errorForm.fullName = 'Set a full name';
    }

    const pattern = /^\+?[0-9]{2}\s?0?[0-9]{5}\s?[0-9]{5}$/;
    if (newDocument.phoneNumber !== '' && !pattern.test(newDocument.phoneNumber)) {
      errorForm.phoneNumber = 'Phone number must match pattern: +44 075760 501000';
    }

    const emailPattern = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/;
    if (newDocument.email !== '' && !emailPattern.test(newDocument.email)) {
      errorForm.email = 'Not a valid email address';
    }

    if (newDocument.gcseYear && newDocument.gcseYear !== '' && (parseInt(newDocument.gcseYear, 10) <= 1960 || parseInt(newDocument.gcseYear, 10) >= 2060)) {
      errorForm.gcseYear = '... Be reasonable';
    }

    if (newDocument.addressFirstLine === '') {
      errorForm.addressFirstLine = 'First line cannot be empty';
    }

    if (newDocument.city === '') {
      errorForm.city = 'City must be entered';
    }

    if (newDocument.postcode === '') {
      errorForm.postCode = 'Postcode must be entered';
    }
    // Set errors state
    setErrors(errorForm);
    return valid;
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const newDocument: UserDocument = { ...localUserDoc, [name]: value };
    if (validateForm(newDocument)) {
      setLocalUserDoc((prevForm) => ({ ...prevForm, [name]: value }));
    }
  };

  const toggleSubject = (id: string) => {
    setLocalUserDoc((prevUserDocument) => (prevUserDocument.subjects.includes(id)
      ? {
        ...prevUserDocument,
        subjects: prevUserDocument.subjects.filter((subjectId) => subjectId !== id),
      }
      : {
        ...prevUserDocument,
        subjects: [...prevUserDocument.subjects, id],
      }
    ));
  };

  return (
    <div className="py-8">
      <h1 className="text-2xl font-bold mb-4">Edit User Information</h1>
      <p>Have a look through all of your information and edit it at a glance.</p>
      <div className="divider" />

      <form className="text-left">
        <div className="mb-6">
          <h2 className="text-xl font-bold mb-4">Account Type</h2>
          <div className="flex gap-2 my-2">
            <button
              type="button"
              onClick={() => { setLocalUserDoc({ ...localUserDoc, accountType: 'tutor' }); }}
              className={`btn ${localUserDoc.accountType === 'tutor' ? 'btn-primary' : 'btn-outline'}`}
            >
              Tutr
            </button>
            <button
              type="button"
              onClick={() => { setLocalUserDoc({ ...localUserDoc, accountType: 'student' }); }}
              className={`btn ${localUserDoc.accountType !== 'tutor' ? 'btn-primary' : 'btn-outline'}`}
            >
              Student
            </button>
          </div>

          <h2 className="text-xl font-bold mb-4">Personal Information</h2>
          <div className="form-control">
            <label className="label" htmlFor="fullName">
              <span className="label-text">Full Name</span>
            </label>
            <input
              type="text"
              className="input input-bordered w-full"
              id="fullName"
              name="fullName"
              required
              placeholder="Full Name"
              value={localUserDoc.fullName}
              onChange={handleInputChange}
            />
            {errors.fullName && <p className="text-error mt-2">{errors.fullName}</p>}
          </div>
        </div>

        <div className="mb-6">
          <div className="form-control">
            <label className="label" htmlFor="phone-input">
              <span className="label-text">Phone number:</span>
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 19 18"
                >
                  <path
                    d="M18 13.446a3.02 3.02 0 0 0-.946-1.985l-1.4-1.4a3.054 3.054 0 0 0-4.218 0l-.7.7a.983.983 0 0 1-1.39 0l-2.1-2.1a.983.983 0 0 1 0-1.389l.7-.7a2.98 2.98 0 0 0 0-4.217l-1.4-1.4a2.824 2.824 0 0 0-4.218 0c-3.619 3.619-3 8.229 1.752 12.979C6.785 16.639 9.45 18 11.912 18a7.175 7.175 0 0 0 5.139-2.325A2.9 2.9 0 0 0 18 13.446Z"
                  />
                </svg>
              </div>
              <input
                type="text"
                id="phone-input"
                className="input input-bordered w-full pl-10"
                pattern="\+?[0-9]{2}\s?[0-9]{5}\s?[0-9]{6}"
                placeholder="+44 07576 050100"
                required
                name="phoneNumber"
                value={localUserDoc.phoneNumber}
                onChange={handleInputChange}
              />
            </div>
            {errors.phoneNumber && <p className="text-error mt-2">{errors.phoneNumber}</p>}
            <label className="label">
              <span className="label-text-alt">Select a phone number that matches the format.</span>
            </label>
          </div>
        </div>

        <div className="mb-6">
          <div className="form-control">
            <label className="label" htmlFor="helper-text">
              <span className="label-text">Your email</span>
            </label>
            <input
              type="email"
              id="helper-text"
              className="input input-bordered w-full"
              placeholder="name@tutr.ai"
              name="email"
              value={localUserDoc.email}
              onChange={handleInputChange}
            />
            {errors.email && <p className="text-error mt-2">{errors.email}</p>}
            <label className="label">
              <span className="label-text-alt">
                We&apos;ll never share your details. Read our
                <a href="/privacy-policy" className="link link-primary"> Privacy Policy</a>
                .
              </span>
            </label>
          </div>
        </div>

        <div className="mb-6">
          <div className="form-control">
            <label className="label" htmlFor="gcseYear">
              <span className="label-text">When will/did you take your GCSE&apos;s</span>
            </label>
            <input
              type="number"
              id="gcseYear"
              placeholder="YYYY"
              min="1999"
              max="2020"
              className="input input-bordered w-full"
              name="gcseYear"
              value={localUserDoc.gcseYear}
              onChange={handleInputChange}
            />
            {errors.gcseYear && <p className="text-error mt-2">{errors.gcseYear}</p>}
          </div>
        </div>

        {localUserDoc.accountType === 'tutor' && (
          <>
            <div className="mb-6">
              <div className="form-control">
                <label className="label" htmlFor="addressFirstLine">
                  <span className="label-text">First line of your address</span>
                </label>
                <input
                  type="text"
                  className="input input-bordered w-full"
                  id="addressFirstLine"
                  name="addressFirstLine"
                  required
                  placeholder="Address First Line"
                  value={localUserDoc.addressFirstLine}
                  onChange={handleInputChange}
                />
                {errors.addressFirstLine && <p className="text-error mt-2">{errors.addressFirstLine}</p>}
              </div>
            </div>

            <div className="mb-6">
              <div className="form-control">
                <label className="label" htmlFor="addressSecondLine">
                  <span className="label-text">Second line of your address</span>
                </label>
                <input
                  type="text"
                  className="input input-bordered w-full"
                  id="addressSecondLine"
                  name="addressSecondLine"
                  required
                  placeholder="Second line of your address"
                  value={localUserDoc.addressSecondLine}
                  onChange={handleInputChange}
                />
                {errors.addressSecondLine && <p className="text-error mt-2">{errors.addressSecondLine}</p>}
              </div>
            </div>

            <div className="grid gap-6 mb-6 md:grid-cols-2">
              <div className="form-control">
                <label className="label" htmlFor="city">
                  <span className="label-text">City</span>
                </label>
                <input
                  type="text"
                  id="city"
                  className="input input-bordered w-full"
                  placeholder="London"
                  name="city"
                  value={localUserDoc.city}
                  onChange={handleInputChange}
                />
                {errors.city && <p className="text-error mt-2">{errors.city}</p>}
              </div>
              <div className="form-control">
                <label className="label" htmlFor="postcode">
                  <span className="label-text">Postcode</span>
                </label>
                <input
                  type="text"
                  id="postcode"
                  className="input input-bordered w-full"
                  placeholder="SW67PS"
                  name="postcode"
                  value={localUserDoc.postcode}
                  onChange={handleInputChange}
                />
                {errors.postCode && <p className="text-error mt-2">{errors.postCode}</p>}
              </div>
            </div>
          </>
        )}

        <h2 className="text-xl font-bold mb-4">Subjects</h2>
        <div className="w-full flex justify-center gap-2 flex-wrap max-w-[80%] m-auto">
          {SubjectMapping.map((subject) => (
            <button
              type="button"
              key={subject.id}
              onClick={() => toggleSubject(subject.id)}
              style={{
                backgroundImage: `linear-gradient(to bottom right, ${subject.colors.from}, ${subject.colors.to})`,
              }}
              className="relative inline-flex items-center justify-center p-0.5 mb-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group hover:text-white dark:text-white focus:ring-4 focus:outline-none"
            >
              <span className={`relative px-5 py-2.5 transition-all ease-in duration-75 rounded-md ${
                localUserDoc.subjects.includes(subject.id) ? 'text-white' : 'bg-white dark:bg-gray-900 group-hover:bg-opacity-0'
              }`}
              >
                {subject.label}
              </span>
            </button>
          ))}
        </div>
        <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
          We currently only teach a select few, to request a new subject
          <a href="/contact-us" className="link link-primary"> Contact Us</a>
          .
        </p>
      </form>

      <div className="w-full flex items-center justify-center gap-2 my-8">
        <button
          type="button"
          onClick={() => {
            FirebaseFirestoreService.updateDocument(
              'users',
              userDoc.id,
              localUserDoc,
              () => {
                window.location.reload();
              },
              () => {
                console.error('Failed to Update Document');
              },
            );
          }}
          className="btn btn-primary"
        >
          Update Personal Information
        </button>
      </div>
    </div>
  );
};
