import React from 'react';
import { SubjectMapping } from '../../../types/Subjects';
import { LessonProposal } from '../../../types/collections/LessonProposal';

export interface SubjectSelectionProps {
  nextStep: () => void;
  lessonPlan: LessonProposal,
  setLessonPlan: React.Dispatch<React.SetStateAction<LessonProposal>>
}

export const SubjectSelection: React.FC<SubjectSelectionProps> = ({
  nextStep,
  lessonPlan,
  setLessonPlan,
}) => (
  <div className="card flex-1 bg-base-100 shadow-xl">
    <div className="card-body">
      <h2 className="card-title text-3xl font-bold mb-4">Schedule a Lesson</h2>
      <p className="mb-6">
        Book a new session, we&apos;ll match you up with a tutor who&apos;s a good fit for you!
      </p>

      <div className="flex flex-wrap justify-center gap-2 mb-6">
        {SubjectMapping.map((subject) => (
          <button
            key={subject.id}
            onClick={() => {
              setLessonPlan((prevState) => ({
                ...prevState,
                subject: subject.label,
              }));
            }}
            className={`btn ${
              lessonPlan.subject === subject.label ? 'btn-primary' : 'btn-outline'
            }`}
            style={{
              background: lessonPlan.subject === subject.label
                ? `linear-gradient(to bottom right, ${subject.colors.from}, ${subject.colors.to})`
                : 'none',
            }}
            type="button"
          >
            {subject.label}
          </button>
        ))}
      </div>

      <div className="alert alert-info mb-6">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
        <span>
          We currently only teach a select few subjects. To request a new subject,
          {' '}
          <a href="/contact" className="link link-primary-content">Contact Us</a>
          .
        </span>
      </div>

      <div className="form-control w-full max-w-xs mb-6">
        <label className="label">
          <span className="label-text font-bold">What level is this subject?</span>
        </label>
        <select
          value={lessonPlan.level}
          onChange={(event) => {
            setLessonPlan((prevState) => ({
              ...prevState,
              level: event.target.value,
            }));
          }}
          className="select select-bordered w-full max-w-xs"
        >
          <option disabled value="">Select your level</option>
          <option>Primary School</option>
          <option>GCSE Specific</option>
          <option>A-Level</option>
          <option>Undergrad</option>
          <option>Postgrad</option>
        </select>
      </div>

      <div className="card-actions justify-end">
        <button
          onClick={nextStep}
          className="btn btn-primary"
          type="button"
        >
          Next
        </button>
      </div>
    </div>
  </div>
);
