import React from 'react';
import { LessonProposal } from '../../../types/collections/LessonProposal';

export interface TutorPreferencesProps {
  previousStep: () => void,
  nextStep: () => void,
  lessonPlan: LessonProposal,
  setLessonPlan: React.Dispatch<React.SetStateAction<LessonProposal>>
}

export const TutorPreferences: React.FC<TutorPreferencesProps> = ({
  previousStep, nextStep, lessonPlan, setLessonPlan,
}) => (
  <div className="card flex-1 bg-base-100 shadow-xl">
    <div className="card-body">
      <h2 className="card-title text-3xl font-bold mb-4">Tutor Preferences</h2>
      <p className="mb-6">
        Let us know if you have any preferences for your tutor. We will try and use this to
        match you to a tutor who fits your description.
      </p>

      <div className="form-control w-full mb-6">
        <label className="label">
          <span className="label-text font-bold">Gender Preferences</span>
        </label>
        <div className="btn-group">
          {['Male', 'Female', 'No Preference'].map((gender) => (
            <button
              key={gender}
              type="button"
              className={`btn ${lessonPlan.genderPreference === gender ? 'btn-active' : ''}`}
              onClick={() => {
                setLessonPlan((prevState) => ({
                  ...prevState,
                  genderPreference: gender,
                }));
              }}
            >
              {gender}
            </button>
          ))}
        </div>
      </div>

      <div className="form-control w-full mb-6">
        <label className="label" htmlFor="tutorPreferenceNotes">
          <span className="label-text font-bold">Additional Requirements</span>
        </label>
        <textarea
          id="tutorPreferenceNotes"
          className="textarea textarea-bordered h-24"
          placeholder="Write some information for your tutor..."
          value={lessonPlan.tutorPreferenceNotes}
          onChange={(event) => {
            setLessonPlan((prevState) => ({
              ...prevState,
              tutorPreferenceNotes: event.target.value,
            }));
          }}
        />
      </div>

      <div className="card-actions justify-between">
        <button
          type="button"
          onClick={previousStep}
          className="btn btn-outline"
        >
          Back
        </button>
        <button
          type="button"
          onClick={nextStep}
          className="btn btn-primary"
        >
          Next
        </button>
      </div>
    </div>
  </div>
);
