import React, { useEffect, useState } from 'react';
import { Month, getMonthDetails, ScheduledEvent } from '../../types/Calendar';
import { DayButton } from './Day';
import { UserDocument } from '../../types/collections/User';
import { LessonsList } from '../lesson-list/LessonList';
import { StudentDocumentsStatus } from '../../types/collections/StudentDocumentsStatus';
import FirebaseFirestoreService from '../../services/database/strategies/FirebaseFirestoreService';

export interface CalendarViewProps {
  currentMonth: Month;
  changeMonth: (offset: number) => void;
  scheduledEvents: ScheduledEvent[];
}

export const CalendarView: React.FC<CalendarViewProps> = (
  { currentMonth, changeMonth, scheduledEvents },
) => {
  const [offSet, setOffset] = useState(0);
  const getScheduledEvent = (date: Date) => scheduledEvents
    .find((event) => event.date.getDate() === date.getDate()
      && event.date.getMonth() === date.getMonth()
      && event.date.getFullYear() === date.getFullYear());

  return (
    <div className="card w-full bg-base-100 shadow-xl">
      <div className="card-body">
        <div className="flex justify-between items-center mb-4">
          <button
            type="button"
            onClick={() => {
              changeMonth(offSet - 1);
              setOffset((prevState) => prevState - 1);
            }}
            className="btn btn-circle btn-sm"
          >
            ❮
          </button>
          <h2 className="card-title">{currentMonth.name}</h2>
          <button
            type="button"
            onClick={() => {
              changeMonth(offSet + 1);
              setOffset((prevState) => prevState + 1);
            }}
            className="btn btn-circle btn-sm"
          >
            ❯
          </button>
        </div>
        <div className="grid grid-cols-7 gap-1">
          {currentMonth.days.map((day) => (
            <DayButton
              key={day.date.toString()}
              day={day}
              scheduledEvent={getScheduledEvent(day.date)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export interface CalendarProps {
  userDoc: UserDocument;
}

export const Calendar: React.FC<CalendarProps> = ({ userDoc }) => {
  const [currentMonth, setCurrentMonth] = useState<Month>(() => getMonthDetails(new Date()));
  const [isLessonsExpanded, setIsLessonsExpanded] = useState(true);
  const [
    studentDocumentChecks,
    setStudentDocumentChecks,
  ] = useState<StudentDocumentsStatus | undefined>(undefined);

  useEffect(() => {
    let unsubscribe: (() => void) | undefined;

    const setupListener = async () => {
      if (userDoc.uid) {
        // Then set up the listener
        unsubscribe = FirebaseFirestoreService.listenToDocument<StudentDocumentsStatus>(
          'student-document-status',
          userDoc.uid,
          (doc) => {
            console.log('collected Document updated:', doc);
            if (doc) {
              setStudentDocumentChecks(doc);
            }
          },
          (error) => {
            console.error('Error listening to document:', error);
          },
        );
      }
    };

    setupListener();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [userDoc]);

  const changeMonth = (offset: number) => {
    const current = new Date(
      currentMonth.days[0].date.getFullYear(),
      currentMonth.days[0].date.getMonth() + offset,
    );
    setCurrentMonth(getMonthDetails(current));
  };

  const determineDocuments = (completed: boolean) => {
    if (completed) {
      return (
        <a href="/new-lesson" className="btn btn-primary btn-sm">
          New Lesson
        </a>
      );
    }
    return (
      <div role="alert" className="alert">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          className="stroke-info h-6 w-6 shrink-0"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <span>You need to complete the student documents before creating new lessons.</span>
      </div>
    );
  };

  return (
    <div className="w-full flex flex-col lg:flex-row gap-6">
      <div className="">
        <CalendarView currentMonth={currentMonth} changeMonth={changeMonth} scheduledEvents={[]} />
      </div>
      <div className="flex-1">
        <div className="card w-full bg-base-100 shadow-xl">
          <div className="card-body">
            <div className="collapse collapse-arrow bg-base-200">
              <input
                type="checkbox"
                checked={isLessonsExpanded}
                onChange={() => setIsLessonsExpanded(!isLessonsExpanded)}
              />
              <div className="collapse-title text-xl font-medium flex justify-between items-center">
                <h2 className="card-title text-2xl">Lessons Scheduled</h2>
              </div>
              <div className="collapse-content flex flex-col gap-2">
                {userDoc.accountType === 'student' && determineDocuments(!studentDocumentChecks || studentDocumentChecks.onboardingComplete)}
                <LessonsList isStudent={userDoc.accountType === 'student'} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
