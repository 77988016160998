import React from 'react';
import { Link } from 'react-router-dom';

interface NavigationCardData {
  title: string;
  description: string;
  color: string;
  location: string;
}

const studentCards: NavigationCardData[] = [
  {
    title: 'Find a Tutor',
    description: 'Browse through our qualified tutors and find the perfect match for your learning needs.',
    color: 'primary',
    location: '/find-tutor',
  },
  {
    title: 'Schedule a Lesson',
    description: 'Book your next lesson and manage your learning calendar.',
    color: 'secondary',
    location: '/schedule-lesson',
  },
  {
    title: 'Study Resources',
    description: 'Access a wealth of study materials, practice tests, and educational content.',
    color: 'accent',
    location: '/resources',
  },
  {
    title: 'Track Progress',
    description: 'Monitor your academic growth and set new learning goals.',
    color: 'info',
    location: '/progress',
  },
  {
    title: 'Join Study Groups',
    description: 'Connect with peers and participate in collaborative learning sessions.',
    color: 'success',
    location: '/study-groups',
  },
];

const tutorCards: NavigationCardData[] = [
  {
    title: 'Manage Students',
    description: 'View and manage your current students, their progress, and schedules.',
    color: 'primary',
    location: '/manage-students',
  },
  {
    title: 'Create Lesson Plans',
    description: 'Design and organize your lesson plans for upcoming sessions.',
    color: 'secondary',
    location: '/lesson-plans',
  },
  {
    title: 'Update Availability',
    description: 'Set your teaching hours and manage your availability calendar.',
    color: 'accent',
    location: '/availability',
  },
  {
    title: 'Performance Analytics',
    description: 'Review your teaching performance and student feedback.',
    color: 'info',
    location: '/analytics',
  },
  {
    title: 'Professional Development',
    description: 'Access resources to enhance your teaching skills and methodologies.',
    color: 'success',
    location: '/professional-development',
  },
];

interface NavigationCardsProps {
  isStudent: boolean;
}

const NavigationCard: React.FC<NavigationCardData> = ({
  title, description, location, color,
}) => (
  <Link to={location} className={`card bg-${color} text-${color}-content shadow-xl w-1/2 md:w-1/4 min-w-[200px]`}>
    <div className="card-body">
      <h2 className="card-title">{title}</h2>
      <p>{description}</p>
    </div>
  </Link>
);

export const NavigationCards: React.FC<NavigationCardsProps> = ({ isStudent }) => {
  const cards = isStudent ? studentCards : tutorCards;

  return (
    <div className="flex flex-col gap-4 my-4">
      <p className="text-4xl font-bold">Explore</p>
      <div className="w-full flex gap-4 overflow-x-auto pb-4 my-2">
        {cards.map((card) => (
          <NavigationCard
            key={card.title}
            title={card.title}
            description={card.description}
            color={card.color}
            location={card.location}
          />
        ))}
      </div>
    </div>
  );
};

export default NavigationCards;
