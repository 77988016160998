import React, { useEffect, useState } from 'react';
import Jdenticon from 'react-jdenticon';
import { Chat, LessonRequest, Message } from '../../../../types/collections/Chats';
import { getMonthDetails, ScheduledEvent } from '../../../../types/Calendar';
import { CalendarView } from '../../../calendar/Calendar';
import { useAuth } from '../../../../contexts/AuthContext';
import FirebaseFirestoreService from '../../../../services/database/strategies/FirebaseFirestoreService';
import { UserDocument } from '../../../../types/collections/User';
import StripePaymentService from '../../../../services/payments/strategies/StripePaymentService';

interface SystemMessageProps {
  chat: Chat;
  message: Message;
}

const parseDateString = (dateString: string): Date => {
  const [year, month, day] = dateString.split('-').map(Number);
  return new Date(year, month - 1, day);
};

const SystemMessage: React.FC<SystemMessageProps> = ({ chat, message }) => {
  const startDate = message.lessonRequest?.startDate
    ? parseDateString(message.lessonRequest?.startDate)
    : new Date();
  const [currentDate, setCurrentDate] = useState(
    message.lessonRequest?.startDate
      ? parseDateString(message.lessonRequest?.startDate)
      : new Date(),
  );
  const { authState } = useAuth();
  // const [tutorDoc, setTutorProfile] = useState<TutorProfileType | undefined>(undefined);
  const [studentUserDoc, setStudentUserDoc] = useState<UserDocument| undefined>(undefined);
  // const [tutorUserDoc, setTutorUserDoc] = useState<UserDocument| undefined>(undefined);
  const [isStudent, setIsStudent] = useState(false);
  const [isParticipant1, setIsParticipant1] = useState(false);

  useEffect(() => {
    console.log(isStudent);
    console.log(isParticipant1);
  }, [isStudent, isParticipant1]);

  useEffect(() => {
    FirebaseFirestoreService.queryDocuments(
      'users',
      'uid',
      chat.participant1Id,
      'fullName',
      1,
      undefined,
      (docs) => {
        if (docs.length > 0) {
          if (isParticipant1 && isStudent) {
            setStudentUserDoc(docs[0] as UserDocument);
          }
        }
      },
      (error) => {
        console.log(error);
      },
    );
    FirebaseFirestoreService.queryDocuments(
      'users',
      'uid',
      chat.participant2Id,
      'fullName',
      1,
      undefined,
      (docs) => {
        if (docs.length > 0) {
          if (!isParticipant1 && isStudent) {
            setStudentUserDoc(docs[0] as UserDocument);
          }
        }
      },
      (error) => {
        console.log(error);
      },
    );
  }, [chat, isStudent, isParticipant1]);

  const changeMonth = (offset: number) => {
    setCurrentDate(new Date(startDate.getFullYear(), startDate.getMonth() + offset, 1));
  };

  const generateDatesForLesson = (lesson: LessonRequest): Date[] => {
    const dates: Date[] = [];

    const firstDate = startDate;
    for (let i = 0; i < lesson.numberOfLessons; i += 1) {
      if (lesson.recurrence.frequency === 'once') {
        dates.push(firstDate);
      } else if (lesson.recurrence.frequency === 'daily') {
        firstDate.setDate(startDate.getDate() + i);
        dates.push(firstDate);
      } else if (lesson.recurrence.frequency === 'weekly') {
        firstDate.setDate(startDate.getDate() + i * 7);
        if (lesson.recurrence.daysOfWeek.includes(currentDate.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase())) {
          dates.push(firstDate);
        }
      } else if (lesson.recurrence.frequency === 'monthly') {
        firstDate.setMonth(startDate.getMonth() + i * lesson.recurrence.interval);
        dates.push(firstDate);
      }
    }
    console.log(dates);
    return dates;
  };

  const takeDatesAndReturnScheduledEvents = (dates: Date[]) : ScheduledEvent[] => (
    dates.map((elem) => ({
      date: elem,
      component:
  <ul>
    <li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">{elem.toString()}</li>
    <li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Set unavailable</li>
    <li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">View events</li>
  </ul>,
    } as ScheduledEvent))
  );

  useEffect(() => {
    if (authState.user) {
      const userId = authState.user.uid;
      FirebaseFirestoreService.getDocument(
        'tutor-profile',
        userId,
        (doc) => {
          if (doc) {
            // setTutorProfile(doc as TutorProfileType);
            setIsStudent(false);
          } else {
            setIsParticipant1(userId === chat.participant1Id);
            setIsStudent(true);
          }
        },
        () => console.log('Failed to fetch tutor profile'),
      );
    }
  }, [authState, chat.participant1Id, message]);

  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [paymentError, setPaymentError] = useState<string | null>(null);

  const handlePayment = async () => {
    if (!message.lessonRequest || !studentUserDoc?.stripeCustomerId) {
      setPaymentError('Unable to process payment. Please contact support.');
      return;
    }

    setIsProcessingPayment(true);
    setPaymentError(null);

    try {
      const amount = message.lessonRequest.cost * 100;
      const currency = 'gbp';

      // Create a Checkout Session
      await StripePaymentService.createCheckoutSession(
        amount,
        currency,
        studentUserDoc.stripeCustomerId,
        `${window.location.origin}/payment-success?message_id=${message.id}`, // Success URL
        `${window.location.origin}/payment-cancel?message_id=${message.id}`, // Cancel URL
        (sessionUrl) => {
          // Redirect to Stripe Checkout
          window.location.href = sessionUrl;
        },
        (error) => {
          console.error('Error creating checkout session:', error);
          setPaymentError('Failed to initiate payment. Please try again.');
          setIsProcessingPayment(false);
        },
      );
    } catch (error) {
      console.error('Error in handlePayment:', error);
      setPaymentError('An unexpected error occurred. Please try again.');
      setIsProcessingPayment(false);
    }
  };

  const handleConfirmLesson = () => {
    FirebaseFirestoreService.updateDocument(
      'messages',
      message.id,
      {
        lessonStatus: 'confirmed',
      },
      () => {
        console.log('Lesson Confirmed');
      },
    );
  };

  const getTutorName = () => {
    if (isStudent) {
      if (isParticipant1) {
        return chat.participant2Name;
      }
      return chat.participant1Name;
    }
    if (isParticipant1) {
      return chat.participant1Name;
    }
    return chat.participant2Name;
  };

  const getStudentName = () => {
    if (!isStudent) {
      if (isParticipant1) {
        return chat.participant2Name;
      }
      return chat.participant1Name;
    }
    if (isParticipant1) {
      return chat.participant1Name;
    }
    return chat.participant2Name;
  };

  const renderMessageContent = () => {
    if (message.lessonRequest) {
      return (
        <div className="flex flex-col lg:flex-row gap-4 w-full p-4">
          <div className="flex-1 space-y-4">
            <div className="card bg-primary text-primary-content">
              <div className="card-body">
                <h2 className="card-title">
                  {message.lessonRequest.subject}
                  ,
                  {' '}
                  {message.lessonRequest.level}
                </h2>
                <p>{message.lessonRequest.lessonDetails?.substring(0, 200)}</p>
                <div className="card-actions justify-end">
                  <div className="badge badge-outline">Lesson Proposal</div>
                </div>
              </div>
            </div>

            <div className="divider" />

            <div className="overflow-x-auto">
              <table className="table w-full">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Quantity</th>
                    <th>Cost</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      1Hr Tutoring Session w/
                      {getTutorName()}
                    </td>
                    <td>{message.lessonRequest.numberOfLessons}</td>
                    <td>
                      £
                      {message.lessonRequest.cost}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="space-y-2">
              <div className="flex items-center gap-2">
                <div className="avatar">
                  <div className="w-8 rounded-full">
                    <Jdenticon value={getTutorName()} />
                  </div>
                </div>
                <button
                  type="button"
                  disabled={isStudent || (message.lessonStatus && message.lessonStatus === 'confirmed')}
                  onClick={handleConfirmLesson}
                  className="btn btn-primary btn-sm"
                >
                  Confirm Lesson Time
                </button>
              </div>
              <div className="flex items-center gap-2">
                <div className="avatar">
                  <div className="w-8 rounded-full">
                    <Jdenticon value={getStudentName()} />
                  </div>
                </div>
                <button
                  type="button"
                  disabled={!isStudent || isProcessingPayment || !studentUserDoc?.stripeCustomerId || (message.lessonStatus && message.lessonStatus === 'pending')}
                  onClick={handlePayment}
                  className="btn btn-primary btn-sm"
                >
                  {isProcessingPayment ? 'Processing...' : 'Pay for Lessons'}
                </button>
              </div>
              {isStudent && (message.lessonStatus && message.lessonStatus === 'pending') && (
                <div className="alert alert-warning">
                  <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
                  <span>
                    You have to wait for the tutor to confirm your lesson before you can pay.
                  </span>
                </div>
              )}
              {paymentError && (
                <div className="alert alert-error">
                  <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                  <span>{paymentError}</span>
                </div>
              )}
              {!studentUserDoc?.stripeCustomerId && isStudent && (
                <div className="alert alert-info">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                  <span>Please set up your payment method before proceeding.</span>
                </div>
              )}
              <button
                type="button"
                onClick={() => {
                  FirebaseFirestoreService.updateDocument(
                    'messages',
                    message.id,
                    {
                      status: 'cancelled',
                    },
                    () => {
                      console.log('Cancelled Lesson');
                      // TODO:// remove any scheduled lessons at that exact time.
                    },
                    (err) => {
                      // TODO://
                      console.log('Failed to cancel lesson', err);
                    },
                  );
                }}
                className="btn btn-error btn-sm"
              >
                Cancel Lesson
              </button>
            </div>
          </div>
          <div className="flex-1 space-y-4">
            <CalendarView
              currentMonth={getMonthDetails(currentDate)}
              changeMonth={changeMonth}
              scheduledEvents={
                takeDatesAndReturnScheduledEvents(generateDatesForLesson(message.lessonRequest))
              }
            />
            <div className="alert alert-info">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
              <span>
                <strong>Start Date:</strong>
                {' '}
                {new Date(message.lessonRequest.startDate).toLocaleDateString()}
                {': '}
                {' '}
                {message.lessonRequest.time}
              </span>
            </div>
            <ul className="steps steps-vertical lg:steps-horizontal">
              <li className={`step ${message.lessonStatus === 'confirmed' || message.lessonStatus === 'paid' ? 'step-primary' : ''}`}>Tutor Confirmed Time</li>
              <li className={`step ${message.lessonStatus === 'confirmed' ? 'step-primary' : ''}`}>Student Payment</li>
              <li className="step">Lesson Complete</li>
            </ul>
          </div>
        </div>
      );
    }
    return undefined;
  };

  return (
    <div className="mx-auto">
      <div className="flex items-center justify-center py-2">
        System Message
        <time className="text-xs opacity-50 ml-1">{new Date(message.timestamp).toLocaleString()}</time>
      </div>
      <div className="chat-bubble chat-bubble-accent mx-auto p-4">
        {renderMessageContent()}
      </div>
    </div>
  );
};

export default SystemMessage;
