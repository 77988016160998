import React, { useEffect, useState } from 'react';
import { UserDocument } from '../../../types/collections/User';
import { ExampleTutorProfileCard, TutorProfileType } from '../../../types/TutorProfile';
import TutorProfileForm from './tutor-profile/TutorProfileForm';
import { TutorProfileCard } from '../../tutor-profile-card/TutorProfileCard';
import FirebaseFirestoreService from '../../../services/database/strategies/FirebaseFirestoreService';
import { useAuth } from '../../../contexts/AuthContext';

export interface EditTutorProfileProps {
  userDoc: UserDocument;
}

export const EditTutorProfile: React.FC<EditTutorProfileProps> = ({ userDoc }) => {
  const [tutorProfile, setTutorProfile] = useState<TutorProfileType>(ExampleTutorProfileCard);
  const [profileCurrentlyExists, setCurrentlyExists] = useState(false);
  const { authState } = useAuth();

  useEffect(() => {
    if (authState.user?.uid) {
      FirebaseFirestoreService.getDocument(
        'tutor-profile',
        authState.user.uid,
        (doc) => {
          if (doc) {
            console.log('Successfully retrieved the doc');
            console.log(doc as TutorProfileType);
            setTutorProfile(doc as TutorProfileType);
            setCurrentlyExists(true);
          } else {
            console.log('No document found');
            setCurrentlyExists(false);
          }
        },
        (error) => {
          console.error(error);
          setCurrentlyExists(false);
        },
      );
    }
  }, [userDoc, authState]);

  const updateTutorProfile = (data: TutorProfileType) => {
    if (authState.user?.uid) {
      if (profileCurrentlyExists) {
        FirebaseFirestoreService.updateDocument(
          'tutor-profile',
          authState.user.uid,
          data,
          () => {
            console.log('Updated the tutor profile');
          },
          () => {
            console.error('Failed to update the tutor profile');
          },
        );
      } else {
        FirebaseFirestoreService.setDocument(
          'tutor-profile',
          authState.user.uid,
          data,
          () => {
            console.log('Updated the tutor profile');
          },
          () => {
            console.error('Failed to update the tutor profile');
          },
        );
      }
    }
  };

  return (
    <div className="py-8">
      <h1 className="text-2xl font-bold mb-4">Edit Tutor Profile</h1>
      <p className="text-base-content">Your profile will be shown to students when they choose to aim to find a new tutor.</p>
      <p className="text-base-content">Below is a preview of what your profile card look like.</p>
      <div className="divider" />
      <div className="flex flex-col gap-2">
        <p className="text-base-content/60">*Example card - This is what your students will see.</p>
        <div className="card bg-base-200 shadow-xl">
          <div className="card-body">
            <TutorProfileCard
              userDoc={userDoc}
              tutorProfileType={tutorProfile}
              pickTutor={(tutorProf) => {
                console.log(tutorProf);
              }}
            />
          </div>
        </div>
      </div>
      <div className="divider" />

      <form className="text-left">
        <div className="mb-6">
          <h2 className="text-xl font-bold mb-4">Edit your tutor card</h2>
          <TutorProfileForm
            tutorProfileType={tutorProfile}
            onSave={updateTutorProfile}
          />
        </div>
      </form>
    </div>
  );
};
