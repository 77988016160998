import React, { ChangeEvent, FormEvent, useState } from 'react';
import ScrollableContentLayout from '../layouts/ScrollableContentLayout';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Handle form submission here (e.g., send data to an API)
    console.log('Form submitted:', formData);
    // Reset form after submission
    setFormData({
      name: '', email: '', subject: '', message: '',
    });
  };

  return (
    <ScrollableContentLayout>
      <div className="hero min-h-screen bg-base-200">
        <div className="hero-content flex-col lg:flex-row-reverse gap-10">
          <div className="text-center lg:text-left">
            <h1 className="text-5xl font-bold">Contact Us</h1>
            <p className="py-6">Got a technical issue? Want to send feedback about a beta feature? Need details about our Business plan? Let us know.</p>
            <div className="space-y-2">
              <p className="flex items-center">
                <svg className="w-5 h-5 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 21">
                  <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                    <path d="M8 12a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                    <path d="M13.8 12.938h-.01a7 7 0 1 0-11.465.144h-.016l.141.171c.1.127.2.251.3.371L8 20l5.13-6.248c.193-.209.373-.429.54-.659l.13-.155Z" />
                  </g>
                </svg>
                Address: XXX
              </p>
              <p className="flex items-center">
                <svg className="w-5 h-5 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m16.344 12.168-1.4-1.4a1.98 1.98 0 0 0-2.8 0l-.7.7a1.98 1.98 0 0 1-2.8 0l-2.1-2.1a1.98 1.98 0 0 1 0-2.8l.7-.7a1.981 1.981 0 0 0 0-2.8l-1.4-1.4a1.828 1.828 0 0 0-2.8 0C-.638 5.323 1.1 9.542 4.78 13.22c3.68 3.678 7.9 5.418 11.564 1.752a1.828 1.828 0 0 0 0-2.804Z" />
                </svg>
                Phone Number: XXX
              </p>
              <p className="flex items-center">
                <svg className="w-5 h-5 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 2-8.4 7.05a1 1 0 0 1-1.2 0L1 2m18 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1m18 0v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2" />
                </svg>
                dev@tutr.ai
              </p>
            </div>
          </div>
          <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
            <form onSubmit={handleSubmit} className="card-body">
              <div className="form-control">
                <label className="label" htmlFor="name">
                  <span className="label-text">Your name</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="John Doe"
                  className="input input-bordered"
                  required
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div className="form-control">
                <label className="label" htmlFor="email">
                  <span className="label-text">Your email</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="name@example.com"
                  className="input input-bordered"
                  required
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="form-control">
                <label className="label" htmlFor="subject">
                  <span className="label-text">Subject</span>
                </label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  placeholder="Let us know how we can help you"
                  className="input input-bordered"
                  required
                  value={formData.subject}
                  onChange={handleChange}
                />
              </div>
              <div className="form-control">
                <label className="label" htmlFor="message">
                  <span className="label-text">Your message</span>
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows={6}
                  className="textarea textarea-bordered h-24"
                  placeholder="Leave a comment..."
                  value={formData.message}
                  onChange={handleChange}
                />
              </div>
              <div className="form-control mt-6">
                <button type="submit" className="btn btn-primary">Send message</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ScrollableContentLayout>
  );
};

export default ContactUs;
