import React, { useState, useEffect } from 'react';
import { Chat, LessonRequest, Message } from '../../../../types/collections/Chats';
import FirebaseDatabaseService from '../../../../services/database/strategies/FirebaseFirestoreService';
import { TutorProfileType } from '../../../../types/TutorProfile';
import { Levels } from '../../../../types/Level';

export interface ScheduleLessonsProps {
  hideState: () => void;
  chat: Chat;
  tutorProfileType: TutorProfileType;
}

export const ScheduleLessons: React.FC<ScheduleLessonsProps> = ({
  hideState,
  chat,
  tutorProfileType,
}) => {
  const [lessonRequest, setLessonsRequest] = useState<LessonRequest>({
    tutorId: '',
    studentId: '',
    lessonDetails: '',
    startDate: '',
    time: '',
    level: 'GCSE' as Levels,
    recurrence: {
      frequency: 'once',
      daysOfWeek: [],
      interval: 1,
    },
    numberOfLessons: 1,
    subject: '',
    cost: 0,
  });

  const [availableSubjects, setAvailableSubjects] = useState<string[]>([]);
  const [availableLevels, setAvailableLevels] = useState<Levels[]>([]);

  useEffect(() => {
    setAvailableSubjects(Object.keys(tutorProfileType.pricing));

    if (Object.keys(tutorProfileType.pricing).length > 0) {
      const firstSubject = Object.keys(tutorProfileType.pricing)[0];
      const firstLevel = Object.keys(tutorProfileType.pricing[firstSubject])[0] as Levels;
      setLessonsRequest((prev) => ({
        ...prev,
        subject: firstSubject,
        level: firstLevel,
      }));
    }
  }, [tutorProfileType]);

  useEffect(() => {
    if (lessonRequest.subject) {
      setAvailableLevels(Object.keys(tutorProfileType.pricing[lessonRequest.subject]) as Levels[]);
    }
  }, [lessonRequest.subject, tutorProfileType.pricing]);

  useEffect(() => {
    if (lessonRequest.subject && lessonRequest.level) {
      const costPerLesson = tutorProfileType.pricing[lessonRequest.subject][lessonRequest.level];
      setLessonsRequest((prev) => ({
        ...prev,
        cost: costPerLesson * prev.numberOfLessons,
      }));
    }
  }, [
    lessonRequest.subject,
    lessonRequest.level,
    lessonRequest.numberOfLessons,
    tutorProfileType.pricing,
  ]);

  const handleChange = (e: React.ChangeEvent<
    HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
  >) => {
    const { name, value } = e.target;
    setLessonsRequest((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleRecurrenceChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setLessonsRequest((prev) => ({
      ...prev,
      recurrence: {
        ...prev.recurrence,
        frequency: value,
      },
    } as LessonRequest));
  };

  const handleDaysOfWeekChange = (day: string) => {
    setLessonsRequest((prev) => ({
      ...prev,
      recurrence: {
        ...prev.recurrence,
        daysOfWeek: prev.recurrence.daysOfWeek.includes(day)
          ? prev.recurrence.daysOfWeek.filter((d) => d !== day)
          : [...prev.recurrence.daysOfWeek, day],
      },
    }));
  };

  const bookLesson = () => {
    const message: Message = {
      id: '',
      sender: 'system',
      timestamp: Date.now(),
      systemType: 'lesson_schedule',
      lessonRequest,
      lessonStatus: 'pending',
      status: 'sent',
      chatId: chat.id,
    };

    FirebaseDatabaseService.addDocument(
      'messages',
      { ...message },
      (newMessageId) => {
        console.log('Message updated:', newMessageId);
        FirebaseDatabaseService.updateDocument(
          'chat',
          chat.id,
          {
            lastMessage: 'Lesson Proposed!',
            lastMessageTime: Date.now(),
          },
          () => {
            hideState();
          },
        );
      },
      (error) => {
        console.log(error.message);
      },
    );
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-base-100 rounded-lg shadow-xl w-full max-w-2xl">
        <div className="flex items-center justify-between p-4 border-b">
          <h3 className="text-xl font-semibold">Schedule Lesson</h3>
          <button
            onClick={hideState}
            className="btn btn-sm btn-circle btn-ghost"
            type="button"
          >
            ✕
          </button>
        </div>
        <div className="p-4 space-y-4">
          <div className="form-control">
            <label className="label" htmlFor="startDate">
              <span className="label-text">Start Date</span>
            </label>
            <input
              type="date"
              id="startDate"
              name="startDate"
              value={lessonRequest.startDate}
              onChange={handleChange}
              className="input input-bordered"
              min={new Date().toISOString().split('T')[0]}
            />
          </div>
          <div className="form-control">
            <label className="label" htmlFor="time">
              <span className="label-text">Time</span>
            </label>
            <input
              type="time"
              id="time"
              name="time"
              value={lessonRequest.time}
              onChange={handleChange}
              className="input input-bordered"
            />
          </div>
          <div className="form-control">
            <label className="label" htmlFor="recurrence">
              <span className="label-text">Recurrence</span>
            </label>
            <select
              id="recurrence"
              name="recurrence"
              value={lessonRequest.recurrence.frequency}
              onChange={handleRecurrenceChange}
              className="select select-bordered"
            >
              <option value="once">One-time</option>
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
            </select>
          </div>
          {lessonRequest.recurrence.frequency === 'weekly' && (
            <div className="form-control">
              <label className="label">
                <span className="label-text">Days of Week</span>
              </label>
              <div className="flex flex-wrap gap-2">
                {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day) => (
                  <button
                    key={day}
                    onClick={() => handleDaysOfWeekChange(day)}
                    className={`btn btn-sm ${
                      lessonRequest.recurrence.daysOfWeek.includes(day)
                        ? 'btn-primary'
                        : 'btn-outline'
                    }`}
                    type="button"
                  >
                    {day}
                  </button>
                ))}
              </div>
            </div>
          )}
          <div className="form-control">
            <label className="label" htmlFor="numberOfLessons">
              <span className="label-text">Number of Lessons</span>
            </label>
            <input
              type="number"
              id="numberOfLessons"
              name="numberOfLessons"
              value={lessonRequest.numberOfLessons}
              onChange={handleChange}
              className="input input-bordered"
              min="1"
            />
          </div>
          <div className="form-control">
            <label className="label" htmlFor="subject">
              <span className="label-text">Subject</span>
            </label>
            <select
              id="subject"
              name="subject"
              value={lessonRequest.subject}
              onChange={handleChange}
              className="select select-bordered"
            >
              {availableSubjects.map((subject) => (
                <option key={subject} value={subject}>{subject}</option>
              ))}
            </select>
          </div>
          <div className="form-control">
            <label className="label" htmlFor="level">
              <span className="label-text">Level</span>
            </label>
            <select
              id="level"
              name="level"
              value={lessonRequest.level}
              onChange={handleChange}
              className="select select-bordered"
            >
              {availableLevels.map((level) => (
                <option key={level} value={level}>{level}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="flex items-center justify-between p-4 border-t">
          <div>
            <button
              onClick={bookLesson}
              className="btn btn-primary"
              type="button"
            >
              Propose Lesson
            </button>
            <button
              onClick={hideState}
              className="btn btn-ghost ml-2"
              type="button"
            >
              Cancel
            </button>
          </div>
          <div className="text-xl font-bold">
            £
            {lessonRequest.cost}
            {' '}
            total
          </div>
        </div>
      </div>
    </div>
  );
};
