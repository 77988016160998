import React, { useCallback, useEffect, useState } from 'react';
import {
  CheckCircle2, AlertCircle, Clock, Download, Upload,
} from 'lucide-react';
import { UserDocument } from '../../../../types/collections/User';
import FirebaseFirestoreService from '../../../../services/database/strategies/FirebaseFirestoreService';
import FirebaseStorageService from '../../../../services/storage/strategies/FirebaseStorageService';
import { DocumentStatus, StudentDocumentsStatus } from '../../../../types/collections/StudentDocumentsStatus';

export interface StudentDocumentChecksProps {
  userDoc: UserDocument;
}

export const StudentDocumentChecks: React.FC<StudentDocumentChecksProps> = ({ userDoc }) => {
  const [
    studentDocumentChecks,
    setStudentDocumentChecks,
  ] = useState<StudentDocumentsStatus | undefined>(undefined);
  const [uploadProgress, setUploadProgress] = useState<{ [key: string]: number }>({});

  const createInitialDocumentStatus = useCallback((docLocation: string): DocumentStatus => ({
    documentUrl: docLocation,
    read: false,
    readTimestamp: '',
    signedDocumentUrl: '',
    signedTimestamp: '',
    signed: false,
    counterSignedDocumentUrl: '',
    counterSignedTimestamp: '',
    counterSigned: false,
  }), []);

  const createInitialStudentDocumentStatus = useCallback((): StudentDocumentsStatus => ({
    uid: userDoc.uid,
    clientPrivacyPolicy: createInitialDocumentStatus('/documents/Tütr AI - CLIENT Privacy Policy.pdf'),
    studentPrivacyPolicy: createInitialDocumentStatus('/documents/Tütr AI - STUDENT Privacy Policy.pdf'),
    safeGuardingPolicy: createInitialDocumentStatus('/documents/Tütr AI - Safeguarding Policy.pdf'),
    reschedulingPolicy: createInitialDocumentStatus('/documents/Tütr AI - Rescheduling and Attendance Policy.pdf'),
    workingTogether: createInitialDocumentStatus('/documents/Tütr AI - Working Together Policy.pdf'),
    clientTerms: createInitialDocumentStatus('/documents/Tütr AI - Client Terms.pdf'),
    onboardingComplete: false,
  }), [userDoc.uid, createInitialDocumentStatus]);

  const initializeDocument = useCallback(async (): Promise<StudentDocumentsStatus | null> => {
    try {
      return new Promise((resolve) => {
        FirebaseFirestoreService.getDocument<StudentDocumentsStatus>(
          'student-document-status',
          userDoc.uid,
          (document) => {
            if (document) {
              resolve(document);
            } else {
              const initialDocStatus = createInitialStudentDocumentStatus();
              FirebaseFirestoreService.setDocument(
                'student-document-status',
                userDoc.uid,
                initialDocStatus,
                () => {
                  console.log('Document Created');
                  resolve(initialDocStatus);
                },
                (error) => {
                  console.log(error.message);
                  resolve(null);
                },
              );
            }
          },
          (error) => {
            console.log(error.message);
            resolve(null);
          },
        );
      });
    } catch (error) {
      console.error('Error initializing document:', error);
      return null;
    }
  }, [userDoc.uid, createInitialStudentDocumentStatus]);

  useEffect(() => {
    let unsubscribe: (() => void) | undefined;

    const setupListener = async () => {
      if (userDoc.uid) {
        // First, ensure the document exists
        const initialDoc = await initializeDocument();
        if (initialDoc) {
          setStudentDocumentChecks(initialDoc);
        }

        // Then set up the listener
        unsubscribe = FirebaseFirestoreService.listenToDocument<StudentDocumentsStatus>(
          'student-document-status',
          userDoc.uid,
          (doc) => {
            console.log('Document updated:', doc);
            if (doc) {
              setStudentDocumentChecks(doc);
            }
          },
          (error) => {
            console.error('Error listening to document:', error);
          },
        );
      }
    };

    setupListener();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [userDoc.uid, initializeDocument]);

  type DocumentKeys = keyof Omit<StudentDocumentsStatus, 'id' | 'uid' | 'onboardingComplete'>;

  const documents: { key: DocumentKeys; title: string }[] = [
    { key: 'clientPrivacyPolicy', title: 'Client Privacy Policy' },
    { key: 'studentPrivacyPolicy', title: 'Student Privacy Policy' },
    { key: 'safeGuardingPolicy', title: 'Safeguarding Policy' },
    { key: 'reschedulingPolicy', title: 'Rescheduling Policy' },
    { key: 'workingTogether', title: 'Working Together' },
    { key: 'clientTerms', title: 'Client Terms' },
  ];
  const updateDocumentStatus = (key: DocumentKeys, update: Partial<DocumentStatus>) => {
    if (studentDocumentChecks) {
      const updatedStatus = { ...studentDocumentChecks[key], ...update };
      FirebaseFirestoreService.updateDocument(
        'student-document-status',
        userDoc.uid,
        { [key]: updatedStatus },
        () => console.log('Document status updated successfully'),
        (error) => console.log('Error updating document status:', error),
      );
    }
  };

  const handleDownload = async (key: DocumentKeys) => {
    if (studentDocumentChecks && studentDocumentChecks[key]) {
      const link = await FirebaseStorageService.getDownloadURL(
        studentDocumentChecks[key].documentUrl,
      );
      window.open(link, '_blank');
      updateDocumentStatus(key, {
        read: true,
        readTimestamp: new Date().toISOString(),
      });
    }
  };

  const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>, key: DocumentKeys) => {
    const file = event.target.files?.[0];
    if (file && studentDocumentChecks) {
      try {
        const path = `documents/${userDoc.uid}/${key}_signed`;
        const signedDocumentUrl = await FirebaseStorageService.uploadFile(
          path,
          file,
          (progress) => setUploadProgress((prev) => ({ ...prev, [key]: progress })),
        );
        updateDocumentStatus(key, {
          signedDocumentUrl,
          signed: true,
          signedTimestamp: new Date().toISOString(),
        });
      } catch (error) {
        console.error(`Error uploading file for ${key}:`, error);
      }
    }
  };

  const agreeToDocument = async (key: DocumentKeys) => {
    if (studentDocumentChecks) {
      try {
        updateDocumentStatus(key, {
          signed: true,
          signedTimestamp: new Date().toISOString(),
        });
      } catch (error) {
        console.error(`Error uploading file for ${key}:`, error);
      }
    }
  };

  const getStatusIcon = (status: boolean) => (status ? <CheckCircle2 className="text-green-500" /> : <Clock className="text-yellow-500" />);

  const [completed, setCompleted] = useState(false);
  useEffect(() => {
    if (studentDocumentChecks) {
      if (studentDocumentChecks.studentPrivacyPolicy.counterSigned
        && studentDocumentChecks.clientPrivacyPolicy.counterSigned
        && studentDocumentChecks.reschedulingPolicy.counterSigned
        && studentDocumentChecks.clientTerms.counterSigned
        && studentDocumentChecks.safeGuardingPolicy.counterSigned
        && studentDocumentChecks.workingTogether.counterSigned) {
        setCompleted(true);
        FirebaseFirestoreService.updateDocument(
          'student-document-status',
          userDoc.uid,
          { onboardingComplete: true },
          () => console.log('Document status updated successfully'),
          (error) => console.log('Error updating document status:', error),
        );
      }
    }
  }, [studentDocumentChecks, userDoc]);

  if (completed) {
    return <div />;
  }
  return (
    <div className="w-full mx-auto">
      <h2 className="text-4xl font-bold mb-4">Document Checks</h2>
      <p className="mb-2">Before you can begin getting lessons, you need to complete the following steps.</p>
      {studentDocumentChecks ? (
        <div className="carousel carousel-center rounded-box max-w-full p-4 space-x-4">
          {documents.map((doc) => {
            const documentStatus = studentDocumentChecks[doc.key];
            return (
              <div key={doc.key} className="carousel-item">
                <div className="card w-96 shadow-xl">
                  <div className="card-body">
                    <h2 className="card-title text-lg font-bold">{doc.title}</h2>
                    <div className="space-y-2 mt-2">
                      <div className="flex justify-between items-center">
                        <span>Read:</span>
                        {getStatusIcon(documentStatus.read)}
                      </div>
                      <div className="flex justify-between items-center">
                        <span>Signed:</span>
                        {getStatusIcon(documentStatus.signed)}
                      </div>
                      <div className="flex justify-between items-center">
                        <span>Counter Signed:</span>
                        {getStatusIcon(documentStatus.counterSigned)}
                      </div>
                    </div>
                    <div className="card-actions justify-end mt-4">
                      <button
                        type="button"
                        onClick={() => handleDownload(doc.key)}
                        className="btn btn-primary btn-sm"
                      >
                        <Download className="mr-2" size={16} />
                        Document
                      </button>

                      {documentStatus.read && !documentStatus.signed && doc.key === 'clientTerms' && (
                        <div className="w-full">
                          <label htmlFor={`upload-${doc.key}`} className="btn btn-success btn-sm w-full">
                            <Upload className="mr-2" size={16} />
                            Upload Signed Document
                          </label>
                          <input
                            id={`upload-${doc.key}`}
                            type="file"
                            onChange={(e) => handleUpload(e, doc.key)}
                            className="hidden"
                          />
                        </div>
                      )}
                      {documentStatus.read && !documentStatus.signed && doc.key !== 'clientTerms' && (
                        <button
                          type="button"
                          onClick={() => {
                            agreeToDocument(doc.key);
                          }}
                          className="btn btn-success btn-sm w-full"
                        >
                          Accept Terms
                        </button>
                      )}
                    </div>
                    {uploadProgress[doc.key] !== undefined && uploadProgress[doc.key] < 100 && (
                      <div className="mt-2">
                        <progress
                          className="progress progress-primary w-full"
                          value={uploadProgress[doc.key]}
                          max="100"
                        />
                        <p className="text-sm mt-1">
                          Upload progress:
                          {' '}
                          {uploadProgress[doc.key].toFixed(0)}
                          %
                        </p>
                      </div>
                    )}
                    {documentStatus.signed && !documentStatus.counterSigned && (
                      <div className="badge badge-warning mt-2">Waiting for counter-signature</div>
                    )}
                    {documentStatus.counterSigned && (
                      <div className="badge badge-success mt-2">Document fully signed</div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="text-center">
          <AlertCircle className="mx-auto text-warning" size={48} />
          <p className="mt-2">Loading document status...</p>
        </div>
      )}
    </div>
  );
};
